import Header from "./Header";
import Footer from "./Footer";


function DefaultLayout(props){
    return(
        <>
        <Header />
            <div className="main-content">
                {props.children}
            </div>
        <Footer />
        </>
    )
}

export default DefaultLayout;