import DefaultLayout from "../layouts/DefaultLayout";
import React, { useState, useEffect, useContext } from "react";
import { register } from "../api/webApiControl";
import { useLocation } from "react-router";
import { getUserDataByToken } from "../api/webApiControl";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "react-fullscreen-loading";

import banner1 from "../assets/images/hero-banner-1.png";
import { userLoginByCred } from "../api/webApiControl";
import validator from 'validator';
import { setToken } from "../services/jwt_service";


function Login() {
  let windowUrl = window.location.href;
  let url = new URL(windowUrl);
  let nextUrl = url.searchParams.get("next");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState({
    emailError: null, passwordError: null
  })
  const navigate = useNavigate();
  const userLogin = async (event) => {
    event.preventDefault();
    if (errorMessage.emailError == null) {
      setErrorMessage({ ...errorMessage, emailError: "Please enter valid email" });
    }
    if (errorMessage.passwordError == null) {
      setErrorMessage({ ...errorMessage, passwordError: "Please enter password" });
    }
    if (errorMessage.emailError == null || errorMessage.passwordError == null) {
      return '';
    }

    if (!errorMessage.emailError && !errorMessage.passwordError) {
      setLoading(true);
      const response = await userLoginByCred({ email: email.email, password: password.password });
      setLoading(false);
      if (response.data.statusCode === 200) {
        setToken(response.data.data.token);
        if (nextUrl) {
          navigate("/" + nextUrl);
        } else {
          navigate("/profile");
        }
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    }
  }

  const onValueChange = (e) => {
    if (e.target.name === "email") {
      if (!e.target.value && !e.target.value.trim()) {
        setErrorMessage({ passwordError: errorMessage.passwordError, emailError: "Please enter valid email" });
      } else {
        setErrorMessage({ passwordError: errorMessage.passwordError, emailError: "" });
        e.target.value = e.target.value.toLowerCase().trim();
        setEmail({ [e.target.name]: e.target.value });
      }
    } else {
      if (!e.target.value && !e.target.value.trim()) {
        setErrorMessage({ passwordError: "Please enter password", emailError: errorMessage.emailError });
      } else {
        setErrorMessage({ passwordError: "", emailError: errorMessage.emailError });
        setPassword({ [e.target.name]: e.target.value });
      }
    }

  };

  return (
    <DefaultLayout>
      <ToastContainer />
      <div>
        <Loading loading={loading} background="#0046B8" loaderColor="#3498db" />
      </div>

      <div className="logingraytop" id="">
        <div className="logingray">
          <div className="container">
            <div className="row">
              <div className="col-md-8 mx-auto">
                <div className="logingrayinn">
                  <form id="loginForm" onSubmit={userLogin} className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <input type="text" name="email" required className="form-control" id="inputLogin" placeholder="Username or Email Address" autoComplete="new-password" onChange={(event) => onValueChange(event)} />
                        <div className='text-danger'>
                          {errorMessage.emailError ? (<label>
                            {errorMessage.emailError}
                          </label>) : ''}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input type="password" name="password" required className="form-control" id="inputLogin" placeholder="Password" autoComplete="new-password" onChange={(event) => onValueChange(event)} />
                        <div className='text-danger'>
                          {errorMessage.passwordError ? (<label>
                            {errorMessage.passwordError}
                          </label>) : ''}
                        </div>
                      </div>
                    </div>


                    <div className="form-group">
                      <Link className="forgotbtn" to="/forgot-password">Forgot Password</Link>
                    </div>
                    <br></br>
                    <button className="apply-now" id="loginbtnn">Login  <span id="waitLoginID" className="loader-4"> </span></button>

                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );

}

export default Login;
