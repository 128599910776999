import arrowIcon from "../../assets/images/icons/left-arrow-black.svg";
import memberteam from "../../assets/images/Gurvinder-Ahluwalia.jpg";
import memberteam1 from "../../assets/images/Gui-Monteiro.jpg";
import memberteam2 from "../../assets/images/Megha-Shrestha.jpg";
import memberteam3 from "../../assets/images/Isha-Vaidya.jpg";
import memberteam4 from "../../assets/images/ankush.jpg";
import memberteam44 from "../../assets/images/viktor-larionov.jpg";
import memberteam55 from "../../assets/images/goutam-bakshi.jpg";
import memberteam5 from "../../assets/images/Prof-Leo-Brecht.jpg";
import memberteam6 from "../../assets/images/Lili-Zhao.jpg";
import memberteam7 from "../../assets/images/Dr-Patrik-Gisel.jpg";
import memberteam8 from "../../assets/images/Dr-Julien-Weissenberg.jpg";
import memberteam9 from "../../assets/images/Dr-Guenter-Dobrauz-Saldapenna.jpg";
import linkedInIcon from "../../assets/images/icons/linkedin-icon.png";


import memberteam56 from "../../assets/images/marc-andre.jpg";


import memberteam58 from "../../assets/images/André-Siegert.jpg";

import memberteam59 from "../../assets/images/Samson-Lee.jpg";

import memberteam60 from "../../assets/images/Ismail-Malik.jpg";

import memberteam61 from "../../assets/images/Dr-Steven-Neubauer.jpg";

import memberteam62 from "../../assets/images/Dr-Nikolay-Shkilev.jpg";

import memberteam63 from "../../assets/images/Andy-Egli.jpg";


import memberteam75 from "../../assets/images/nishant-shrestha.jpg";


import Modal from "react-bootstrap/Modal";
import { useState, useCallback } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import * as bootstrap from "bootstrap";
import { useNavigate } from "react-router-dom";
import {getToken} from "../../services/jwt_service";

function OurTeam() {
  const navigate = useNavigate();

  function showJoinModal() {
    if (getToken()) {
      navigate('/profile');
    } else {
      let myModal = new bootstrap.Modal(document.getElementById('staticBackdrop'), {});
      myModal.show()
    }
  }
  const options = {
    items: 5,
    loop: true,
    margin: 10,
    nav: false,
    dots: false,
    autoplay: true, 
    autoplayHoverPause:false,
    autoplaySpeed: 14000,
    autoplayTimeout: 13000,
    responsive: {
      0: {
        items: 2
      },
      600: {
        items: 2
      },
      1000: {
        items: 5
      }
    }
  }
  let ourTeamPeople = [

    {

      id: 1,
      name: "Nishant Shrestha",
      designation: "Urban & Real Estate Strategist",
      image: memberteam75,
      linkedIn: "https://www.linkedin.com/in/nishantprabhatshrestha/",
      description: "Nishant is an expert in Urban Management & retailing with a degree from Harvard. He specializes in Retail, Real estate and Mall Management."

    },
    {

      id: 2,
      name: "Gui Monteiro",
      designation: "CMO",
      image: memberteam1,
      linkedIn: "https://www.linkedin.com/in/monteirogui/",
      description: "Gui Monteiro, BBA, is the former professional gamer and CMO of Uber and Buser. He has over 15 years of experience in hyper-growth unicorn startups and new technology companies. Focus on business development, performance marketing, brand building, and customer experience."

    },
    {
      id: 3,
      name: "Megha Shrestha",
      designation: "Founder & CEO",
      image: memberteam2,
      linkedIn: "https://www.linkedin.com/in/megha-shrestha-8376342b/",
      description: "Megha Shrestha, MBA, Msc. in Information Systems has substantial experience in networking events organization. She has worked extensively in project developments from scratch in tertiary education and IT projects and business development in Asia & Middle East"

    },
    {
      id: 4,
      name: "Isha Vaidya",
      designation: "Head Operations",
      image: memberteam3,
      linkedIn: "https://www.linkedin.com/in/isha-vaidya-44762290/",
      description: "Isha Vaidya, MBA, is an expert in event management, operations and quality management."

    },
    {
      id: 5,
      name: "Ankush Sharma",
      designation: "Web 3.0 Expert",
      image: memberteam4,
      linkedIn: "https://in.linkedin.com/in/ankush-sharma-689072ba",
      description: "Experienced professional with a demonstrated history of working in various industry. Strong business development professional skilled in Management, Customer Service, Strategic Planning, Augmented ,Virtual , Mixed Reality and Marketing Strategy."

    },
    {
      id: 6,
      name: "Prof. Dr. Leo Brecht",
      designation: "Strategy and Innovation Expert",
      image: memberteam5,
      linkedIn: "https://www.linkedin.com/in/prof-dr-leo-brecht-a10519a2/",
      description: "Prof. Dr. Leo Brecht (Strategy and Innovation Expert)is an innovation and startup strategy expert."

    },
    {

      id: 7,
      name: "Dr. Guenter Dobrauz-Saldapenna",
      designation: "Legal and sustainability expert",
      image: memberteam9,
      linkedIn: "https://www.linkedin.com/in/dobrauz/",
      description: "Dr. Guenter Dobrauz-Saldapenna, MBA is a Partner at PwC in Zurich and leads PwC Legal in Switzerland. He is also a co-founding Investor of exelixis capital AG investing in circularity and exponential technologies."

    },
    {
      id: 8,
      name: "Lili Zhao",
      designation: "Blockchain ecosystem and community expert",
      image: memberteam6,
      linkedIn: "https://www.linkedin.com/in/lili-zhao-zurich/",
      description: "Lili Zhao, MBA, is a fully grounded in the blockchain ecosystem"

    },
    {
      id: 9,
      name: "Dr. Patrik Gisel",
      designation: "Finance Expert",
      image: memberteam7,
      linkedIn: "https://www.linkedin.com/in/patrik-gisel-555aa22/",
      description: "Dr. Patrik Gisel is an expert in Finance and Strategy former CEO of Bank Raiffeisen"

    },
    {
      id: 10,
      name: "Dr. Julien Weissenberg",
      designation: "AI Expert",
      image: memberteam8,
      linkedIn: "https://www.linkedin.com/in/julienweissenberg/",
      description: "Dr. Julien Weissenberg, PhD., Msc. Advanced computing is an expert of Artificial Intelligence and Founder of VisualSense AI advisory. With 10 years of expertise in AI algorithms, he has been helping build and evaluate AI solutions for a wide array of industries, ranging from agriculture to insurance."

    },


    {
      id: 44,
      name: "Viktor Larionov",
      designation: "Blockchain Economics Expert",
      image: memberteam44,
      linkedIn: "https://www.linkedin.com/in/viktor-larionov-2324731/",
      description: "Co-founder and CEO at the longest standing blockchain advisory firm Priority Token (ptoken.io) and Industry 4.0 Investment Bank (industry4ib.com) - revolutionizing global fintech & blockchain industry.Investor in numerous crypto projects. Partner in one of the biggest Australian crypto fund UniRock Partners.Advisor for a number of disruptive blockchain projects, including Credefi, Immunify, Guardians of Blockchain. Personally supervising off-market investment opportunities in tech industry and dealing with fellow investors (active network of 1000+ private and institutional investors).15+ year background with major VCs in Singapore, Hong Kong and London. Currently based between Melbourne and London with other important bases in Zurich, Milan, London, Dubai, Seoul, Tokyo, Hong Kong and Singapore."
    },



    {
      id: 55,
      name: "Goutam Bakshi",
      designation: "Core Team Member",
      image: memberteam55,
      linkedIn: "http://linkedin.com/in/goutam-bakshi-652a9a3",
      description: "Goutam is a ditstributed and P2P computing enthusiast. He has been working in Blockchain related technology for last 5 years, architecting DLT solutions in XinFin XdPOS network, Ethereum and Hyperledger network, with IPFS as Distributed File System.Some of his successful implementations include Private permissioned SME Invoice Factoring in Heyperledger Fabric, MultiSig Wallet & Asset Tokenization solutions in XinFin network.Goutam also has a deep interest in tokenomics, specifically in NFT/Semifungible utility tokens, Stable coins and  DAO.Goutam is currently a Senior member in Yodaplus Blockchain Experts."
    },



      {
      id: 56,
      name: "Dr. Marc-André Schauwecker",
      designation: "Chairman",
      image: memberteam56,
      linkedIn: "https://www.linkedin.com/mwlite/in/marc-andré-schauwecker",
      description: "Head Legal and Compliance, Conference Organizer and Speaker, President and Chief Strategy, Executive Board Member of Swiss Association of MBAs and President of eTrain"
    },



      {
      id: 57,
      name: "André Siegert",
      designation: "CFO",
      image: memberteam58,
      linkedIn: "https://www.linkedin.com/in/andresiegert/",
      description: "André Siegert, MBA is a creative innovator with almost 15 years' experience leading strategy and change initiatives in Finance and cross-departmentally."
    },




       {
      id: 58,
      name: "Samson Lee ",
      designation: " Digital Asset & Asset Tokenization Expert",
      image: memberteam59,
      linkedIn: "https://www.linkedin.com/in/samsonlee/",
      description: "Samson Lee (or Sam), is a prominent entrepreneur and FinTech executive, who has over 20 years’ experience in TMET sector, with substantial success in commercializing various blockchain, digital and e-business projects."
    },



    {
      id: 59,
       name: "Ismail Malik ",
      designation: "Blockchain Economy Expert",
      image: memberteam60,
      linkedIn: "https://www.linkedin.com/in/blockchain/",
      description: "Ismail Malik is a Co-Founder at MetaFi and CEO & Founder at Blockchain Lab. He provides leadership and strategic direction to support service-focused and cost effective activities related to sourcing, contracting, eMoney, tokenized credit services & DeFi Decentralized Finance."
    },




    {
      id: 60,
      name: "Dr. Steven Neubauer",
      designation: "Strategy Expert",
      image: memberteam61,
      linkedIn: "https://www.linkedin.com/in/stevenneubauer/",
      description: "Dr. Steven Neubauer is the CEO of comparis.ch AG. Former Executive Board Member of Neuer Zürcher Zeitung AG. C-level executive with deep strategic expertise coupled with hands- on experience leading large and complex organizations."
    },





    {
      id: 61,
      name: "Dr. Nikolay Shkilev",
      designation: "Marketing Advisor",
      image: memberteam62,
      linkedIn: "https://www.linkedin.com/in/vipmentor/",
      description: "Dr. Shkilev Nikolay is an entrepreneur, CEO & Founder Private Business Club, rated TOP 3 in People of Blockchain."
    },

     {
      id: 62,
      name: "Andy Egli ",
      designation: "Football Expert",
      image: memberteam63,
      linkedIn: "https://www.linkedin.com",
      description: "Andy Egli is a successfull Swiss football manager and former player for the Switzerland national team, Servette, Xamax, GC and Borussia Dortmund."
    }





  ];
  let [activeTeamMember, setActiveTeamMember] = useState(ourTeamPeople[0]);
  // setActiveTeamMember(ourTeamPeople[0]);
  const showModal = useCallback((id) => () => {
    setActiveTeamMember(ourTeamPeople.find(member => {
      if (member.id === id) {
        return member;
      }
    }))
    document.getElementById('root').classList.add("blurEffect");
    setIsOpen(true);
  }, []);

  const [isOpen, setIsOpen] = useState(false);


  const hideModal = () => {
    document.getElementById('root').classList.remove("blurEffect");
    setIsOpen(false);
  };

  function showJoinModal() {
    if (getToken()) {
      navigate('/profile');
    } else {
      let myModal = new bootstrap.Modal(document.getElementById('staticBackdrop'), {});
      myModal.show()
    }
  }
  return (
    < >
      <Modal show={isOpen} dialogClassName="modal-dialog modal-dialog-centered">
        <Modal.Body className="modelbodynew">
          <div className="section-boddymodel">
            <div className="section-boddymodelleft">
              <img src={activeTeamMember.image} alt="team member" />
            </div>
            <div className="section-boddymodelright">
              <h1 >{activeTeamMember.name}
              </h1>
              <p>{activeTeamMember.designation}    </p>
              <p>{activeTeamMember.description}</p>
              <a href={activeTeamMember.linkedIn} target="_blank">
                <img src={linkedInIcon} />
              </a>
            </div>
          </div>
          <button onClick={hideModal} className="btn-close" id="close_btn_registerone" data-bs-dismiss="modal" aria-label="Close"></button>
        </Modal.Body>
      </Modal>
      <section className="our-team" id="team" >
        <div className="container">
          <div className="section-title text-center teamheading">
            <h4>Meet the Team</h4>
            <p>
              Metabloqs is a solid & visionary product made by a team with many
              years of experience in the “real world”
            </p>
          </div>

          <ul className="teamlist teamnew">
            <OwlCarousel className='owl-theme'  {...options}>
              
              <div className="item">
                <li className={`team-item-li ${isOpen ? 'menu-item-active' : ''}`}>
                  <div className="member-box" onClick={showModal(1)}>
                    <figure>
                      {" "}
                      <img src={memberteam75} alt="" />{" "}
                    </figure>
                    <figcaption>
                      <div className="member-boxdown">
                        <h4> Nishant Shrestha</h4>
                        <h5>Urban & Real Estate Strategist</h5>
                      </div>
                      <span className="left-arrow">
                        {" "}
                        <img src={arrowIcon} alt="" />{" "}
                      </span>
                      <div className="memberInfo">
                      {/*  <p>
                          <a>
                            Read More &nbsp;{" "}
                            <span className="right-arrow">
                              {" "}
                              <img src={arrowIcon} alt="" />{" "}
                            </span>{" "}
                          </a>
                        </p>*/}
                        <p>
                          <a target="blank" href="https://www.linkedin.com/in/nishantprabhatshrestha/">
                            <img src={linkedInIcon} />
                          </a>
                        </p>
                      </div>
                    </figcaption>
                  </div>
                </li>
              </div>

              <div className="item">  
                <li>
                  <div className="member-box" onClick={showModal(2)}>
                    <figure>
                      {" "}
                      <img src={memberteam1} alt="" />{" "}
                    </figure>
                    <figcaption>
                      <div className="member-boxdown">
                        <h4>Gui Monteiro </h4>
                        <h5>CMO</h5>
                      </div>
                      <span className="left-arrow">
                        {" "}
                        <img src={arrowIcon} alt="" />{" "}
                      </span>{" "}
                      <div className="memberInfo">
                        {/* <p>
                          <a>
                            Read More &nbsp;{" "}
                            <span className="right-arrow">
                              {" "}
                              <img src={arrowIcon} alt="" />{" "}
                            </span>{" "}
                          </a>
                        </p>*/}
                        <p>
                          <a target="blank" href="https://www.linkedin.com/in/monteirogui/">
                            <img src={linkedInIcon} />
                          </a>
                        </p>
                      </div>
                    </figcaption>
                  </div>
                </li>
              </div>


              <div className="item">
                <li>
                  <div className="member-box" onClick={showModal(3)}>
                    <figure>
                      {" "}
                      <img src={memberteam2} alt="" />{" "}
                    </figure>
                    <figcaption>
                      <div className="member-boxdown">
                        <h4>Megha Shrestha</h4>
                        <h5>Founder & CEO</h5>
                      </div>
                      <span className="left-arrow">
                        {" "}
                        <img src={arrowIcon} alt="" />{" "}
                      </span>{" "}
                      <div className="memberInfo">
                        {/* <p>
                          <a>
                            Read More &nbsp;{" "}
                            <span className="right-arrow">
                              {" "}
                              <img src={arrowIcon} alt="" />{" "}
                            </span>{" "}
                          </a>
                        </p> */}
                        <p>
                          <a target="blank" href="https://www.linkedin.com/in/megha-shrestha-8376342b/">
                            <img src={linkedInIcon} />
                          </a>
                        </p>
                      </div>
                    </figcaption>
                  </div>
                </li>
              </div>

              <div className="item">
                <li>
                  <div className="member-box" onClick={showModal(56)}>
                    <figure>
                      {" "}
                      <img src={memberteam56} alt="" />{" "}
                    </figure>
                    <figcaption>
                      <div className="member-boxdown">
                        <h4> Dr. Marc-André Schauwecker</h4>
                        <h5>Chairman</h5>
                      </div>
                      <span className="left-arrow">
                        {" "}
                        <img src={arrowIcon} alt="" />{" "}
                      </span>{" "}
                      <div className="memberInfo">
                       {/*  <p>
                          <a>
                            Read More &nbsp;{" "}
                            <span className="right-arrow">
                              {" "}
                              <img src={arrowIcon} alt="" />{" "}
                            </span>{" "}
                          </a>
                        </p>*/}
                        <p>
                          <a target="blank" href="https://www.linkedin.com/mwlite/in/marc-andré-schauwecker">
                            <img src={linkedInIcon} />
                          </a>
                        </p>
                      </div>
                    </figcaption>
                  </div>
                </li>
              </div>


              <div className="item">
                <li>
                  <div className="member-box" onClick={showModal(4)}>
                    <figure>
                      {" "}
                      <img src={memberteam3} alt="" />{" "}
                    </figure>
                    <figcaption>
                      <div className="member-boxdown">
                        <h4> Isha Vaidya </h4>
                        <h5>Head Operations</h5>
                      </div>
                      <span className="left-arrow">
                        {" "}
                        <img src={arrowIcon} alt="" />{" "}
                      </span>{" "}
                      <div className="memberInfo">
                       {/*  <p>
                          <a>
                            Read More &nbsp;{" "}
                            <span className="right-arrow">
                              {" "}
                              <img src={arrowIcon} alt="" />{" "}
                            </span>{" "}
                          </a>
                        </p> */}
                        <p>
                          <a target="blank" href="https://www.linkedin.com/in/isha-vaidya-44762290/">
                            <img src={linkedInIcon} />
                          </a>
                        </p>
                      </div>
                    </figcaption>
                  </div>
                </li>
              </div>


              <div className="item">
                <li>
                  <div className="member-box" onClick={showModal(5)}>
                    <figure>
                      {" "}
                      <img src={memberteam4} alt="" />{" "}
                    </figure>
                    <figcaption>
                      <div className="member-boxdown">
                        <h4> Ankush Sharma </h4>
                        <h5>Immersive Reality Expert</h5>
                      </div>
                      <span className="left-arrow">
                        {" "}
                        <img src={arrowIcon} alt="" />{" "}
                      </span>{" "}
                      <div className="memberInfo">
                       {/*  <p>
                          <a>
                            Read More &nbsp;{" "}
                            <span className="right-arrow">
                              {" "}
                              <img src={arrowIcon} alt="" />{" "}
                            </span>{" "}
                          </a>
                        </p>*/}
                        <p>
                          <a target="blank" href="https://in.linkedin.com/in/ankush-sharma-689072ba">
                            <img src={linkedInIcon} />
                          </a>
                        </p>
                      </div>
                    </figcaption>
                  </div>
                </li>
              </div>

              <div className="item">

                <li>
                  <div className="member-box" onClick={showModal(55)}>
                    <figure>
                      {" "}
                      <img src={memberteam55} alt="" />{" "}
                    </figure>
                    <figcaption>
                      <div className="member-boxdown">
                        <h4>Goutam Bakshi </h4>
                        <h5> Smart Contract Expert</h5>
                      </div>
                      <span className="left-arrow">
                        {" "}
                        <img src={arrowIcon} alt="" />{" "}
                      </span>{" "}
                      <div className="memberInfo">
                       {/*  <p>
                          <a>
                            Read More &nbsp;{" "}
                            <span className="right-arrow">
                              {" "}
                              <img src={arrowIcon} alt="" />{" "}
                            </span>{" "}
                          </a>
                        </p>*/}
                        <p>
                          <a target="blank" href="http://linkedin.com/in/goutam-bakshi-652a9a3">
                            <img src={linkedInIcon} />
                          </a>
                        </p>
                      </div>
                    </figcaption>
                  </div>
                </li>

              </div>


              <div className="item">

                <li>
                  <div className="member-box" onClick={showModal(57)}>
                    <figure>
                      {" "}
                      <img src={memberteam58} alt="" />{" "}
                    </figure>
                    <figcaption>
                      <div className="member-boxdown">
                        <h4>André Siegert </h4>
                        <h5> CFO</h5>
                      </div>
                      <span className="left-arrow">
                        {" "}
                        <img src={arrowIcon} alt="" />{" "}
                      </span>{" "}
                      <div className="memberInfo">
                       {/*  <p>
                          <a>
                            Read More &nbsp;{" "}
                            <span className="right-arrow">
                              {" "}
                              <img src={arrowIcon} alt="" />{" "}
                            </span>{" "}
                          </a>
                        </p>*/}
                        <p>
                          <a target="blank" href="">
                            <img src={linkedInIcon} />
                          </a>
                        </p>
                      </div>
                    </figcaption>
                  </div>
                </li>

              </div>

            </OwlCarousel>

          </ul>
          <div className="row">
        {/*  <div className="section-title text-center teamheading">
              <h4> Our Advisors</h4>
            </div>
            */}  

            <ul className="teamlist teamnew">
              <OwlCarousel className='owl-theme' {...options}>

                <div className="item">
                  <li>
                    <div className="member-box" onClick={showModal(6)}>
                      <figure>
                        {" "}
                        <img src={memberteam5} alt="" />{" "}
                      </figure>
                      <figcaption>
                        <div className="member-boxdown">
                          <h4>Prof. Dr. Leo Brecht </h4>
                          <h5>Strategy and Innovation Expert</h5>
                        </div>
                        <span className="left-arrow">
                          {" "}
                          <img src={arrowIcon} alt="" />{" "}
                        </span>
                        <div className="memberInfo">
                          {/* <p>
                            <a>
                              Read More &nbsp;{" "}
                              <span className="right-arrow">
                                {" "}
                                <img src={arrowIcon} alt="" />{" "}
                              </span>{" "}
                            </a>
                          </p> */}
                          <p>
                            <a target="blank" href="https://www.linkedin.com/in/prof-dr-leo-brecht-a10519a2/">
                              <img src={linkedInIcon} />
                            </a>
                          </p>
                        </div>
                      </figcaption>
                    </div>
                  </li>
                </div>

                <div className="item">
                  <li>
                    <div className="member-box" onClick={showModal(8)}>
                      <figure>
                        {" "}
                        <img src={memberteam6} alt="" />{" "}
                      </figure>
                      <figcaption>
                        <div className="member-boxdown">
                          <h4> Lili Zhao </h4>
                          <h5> Blockchain ecosystem and community expert</h5>
                        </div>
                        <span className="left-arrow">
                          {" "}
                          <img src={arrowIcon} alt="" />{" "}
                        </span>{" "}
                        <div className="memberInfo">
                         {/*  <p>
                            <a>
                              Read More &nbsp;{" "}
                              <span className="right-arrow">
                                {" "}
                                <img src={arrowIcon} alt="" />{" "}
                              </span>{" "}
                            </a>
                          </p> */}
                          <p>
                            <a target="blank" href="https://www.linkedin.com/in/lili-zhao-zurich/">
                              <img src={linkedInIcon} />
                            </a>
                          </p>
                        </div>
                      </figcaption>
                    </div>
                  </li>

                </div>


                <div className="item">
                  <li>
                    <div className="member-box" onClick={showModal(9)}>
                      <figure>
                        {" "}
                        <img src={memberteam7} alt="" />{" "}
                      </figure>
                      <figcaption>
                        <div className="member-boxdown">
                          <h4> Dr. Patrik Gisel </h4>
                          <h5>Finance Expert</h5>
                        </div>
                        <span className="left-arrow">
                          {" "}
                          <img src={arrowIcon} alt="" />{" "}
                        </span>{" "}
                        <div className="memberInfo">
                          {/* <p>
                            <a>
                              Read More &nbsp;{" "}
                              <span className="right-arrow">
                                {" "}
                                <img src={arrowIcon} alt="" />{" "}
                              </span>{" "}
                            </a>
                          </p> */} 
                          <p>
                            <a target="blank" href="https://www.linkedin.com/in/patrik-gisel-555aa22/">
                              <img src={linkedInIcon} />
                            </a>
                          </p>
                        </div>
                      </figcaption>
                    </div>
                  </li>

                </div>

                <div className="item">
                  <li>
                    <div className="member-box" onClick={showModal(10)}>
                      <figure>
                        {" "}
                        <img src={memberteam8} alt="" />{" "}
                      </figure>
                      <figcaption>
                        <div className="member-boxdown">
                          <h4> Dr. Julien Weissenberg </h4>
                          <h5>AI Expert</h5>
                        </div>
                        <span className="left-arrow">
                          {" "}
                          <img src={arrowIcon} alt="" />{" "}
                        </span>{" "}
                        <div className="memberInfo">
                         {/*  <p>
                            <a>
                              Read More &nbsp;{" "}
                              <span className="right-arrow">
                                {" "}
                                <img src={arrowIcon} alt="" />{" "}
                              </span>{" "}
                            </a>
                          </p> */}
                          <p>
                            <a target="blank" href="https://www.linkedin.com/in/julienweissenberg/">
                              <img src={linkedInIcon} />
                            </a>
                          </p>
                        </div>
                      </figcaption>
                    </div>
                  </li>

                </div>


                <div className="item">
                  <li>
                    <div className="member-box" onClick={showModal(7)}>
                      <figure>
                        {" "}
                        <img src={memberteam9} alt="" />{" "}
                      </figure>
                      <figcaption>
                        <div className="member-boxdown">
                          <h4> Dr. Guenter Dobrauz-Saldapenna </h4>
                          <h5> Legal and sustainability expert</h5>
                        </div>
                        <span className="left-arrow">
                          {" "}
                          <img src={arrowIcon} alt="" />{" "}
                        </span>{" "}
                        <div className="memberInfo">
                         {/*  <p>
                            <a>
                              Read More &nbsp;{" "}
                              <span className="right-arrow">
                                {" "}
                                <img src={arrowIcon} alt="" />{" "}
                              </span>{" "}
                            </a>
                          </p> */}
                          <p>
                            <a target="blank" href="https://www.linkedin.com/in/dobrauz/">
                              <img src={linkedInIcon} />
                            </a>
                          </p>
                        </div>
                      </figcaption>
                    </div>
                  </li>

                </div>


                <div className="item">
                  <li>
                    <div className="member-box" onClick={showModal(44)}>
                      <figure>
                        {" "}
                        <img src={memberteam44} alt="" />{" "}
                      </figure>
                      <figcaption>
                        <div className="member-boxdown">
                          <h4> Viktor Larionov </h4>
                          <h5>Blockchain Economics Expert</h5>
                        </div>
                        <span className="left-arrow">
                          {" "}
                          <img src={arrowIcon} alt="" />{" "}
                        </span>{" "}
                        <div className="memberInfo">
                         {/*  <p>
                            <a>
                              Read More &nbsp;{" "}
                              <span className="right-arrow">
                                {" "}
                                <img src={arrowIcon} alt="" />{" "}
                              </span>{" "}
                            </a>
                          </p> */} 
                          <p>
                            <a target="blank" href="https://www.linkedin.com/in/viktor-larionov-2324731/">
                              <img src={linkedInIcon} />
                            </a>
                          </p>
                        </div>
                      </figcaption>
                    </div>
                  </li>
                </div>



                 <div className="item">

                        <li>
                        <div className="member-box" onClick={showModal(58)}>
                        <figure>
                          {" "}
                          <img src={memberteam59} alt="" />{" "}
                        </figure>
                        <figcaption>
                          <div className="member-boxdown">
                            <h4>Samson Lee  </h4>
                            <h5>  Digital Asset & Asset Tokenization Expert</h5>
                          </div>
                          <span className="left-arrow">
                            {" "}
                            <img src={arrowIcon} alt="" />{" "}
                          </span>{" "}
                          <div className="memberInfo">
                           {/*  <p>
                              <a>
                                Read More &nbsp;{" "}
                                <span className="right-arrow">
                                  {" "}
                                  <img src={arrowIcon} alt="" />{" "}
                                </span>{" "}
                              </a>
                            </p>*/}
                            <p>
                              <a target="blank" href="">
                                <img src={linkedInIcon} />
                              </a>
                            </p>
                          </div>
                        </figcaption>
                         </div>
                          </li>

                   </div>


                  <div className="item">

                      <li>
                        <div className="member-box" onClick={showModal(59)}>
                        <figure>
                          {" "}
                          <img src={memberteam60} alt="" />{" "}
                        </figure>
                        <figcaption>
                          <div className="member-boxdown">
                            <h4>Ismail Malik  </h4>
                            <h5>  Blockchain Economy Expert</h5>
                          </div>
                          <span className="left-arrow">
                            {" "}
                            <img src={arrowIcon} alt="" />{" "}
                          </span>{" "}
                          <div className="memberInfo">
                           {/*  <p>
                              <a>
                                Read More &nbsp;{" "}
                                <span className="right-arrow">
                                  {" "}
                                  <img src={arrowIcon} alt="" />{" "}
                                </span>{" "}
                              </a>
                            </p>*/}
                            <p>
                              <a target="blank" href="">
                                <img src={linkedInIcon} />
                              </a>
                            </p>
                          </div>
                        </figcaption>
                         </div>
                       </li>

                     </div>





                    <div className="item">

                      <li>
                        <div className="member-box" onClick={showModal(60)}>
                        <figure>
                          {" "}
                          <img src={memberteam61} alt="" />{" "}
                        </figure>
                        <figcaption>
                          <div className="member-boxdown">
                            <h4>Dr. Steven Neubauer  </h4>
                            <h5>  Strategy Expert</h5>
                          </div>
                          <span className="left-arrow">
                            {" "}
                            <img src={arrowIcon} alt="" />{" "}
                          </span>{" "}
                          <div className="memberInfo">
                           {/*  <p>
                              <a>
                                Read More &nbsp;{" "}
                                <span className="right-arrow">
                                  {" "}
                                  <img src={arrowIcon} alt="" />{" "}
                                </span>{" "}
                              </a>
                            </p>*/}
                            <p>
                              <a target="blank" href="">
                                <img src={linkedInIcon} />
                              </a>
                            </p>
                          </div>
                        </figcaption>
                         </div>
                       </li>

                     </div>




                     <div className="item">

                        <li>
                        <div className="member-box" onClick={showModal(61)}>
                        <figure>
                          {" "}
                          <img src={memberteam62} alt="" />{" "}
                        </figure>
                        <figcaption>
                          <div className="member-boxdown">
                            <h4>Dr. Nikolay Shkilev  </h4>
                            <h5>  Marketing Advisor </h5>
                          </div>
                          <span className="left-arrow">
                            {" "}
                            <img src={arrowIcon} alt="" />{" "}
                          </span>{" "}
                          <div className="memberInfo">
                           {/*  <p>
                              <a>
                                Read More &nbsp;{" "}
                                <span className="right-arrow">
                                  {" "}
                                  <img src={arrowIcon} alt="" />{" "}
                                </span>{" "}
                              </a>
                            </p>*/}
                            <p>
                              <a target="blank" href="">
                                <img src={linkedInIcon} />
                              </a>
                            </p>
                          </div>
                        </figcaption>
                         </div>
                       </li>

                     </div>


                      <div className="item">

                        <li>
                        <div className="member-box" onClick={showModal(62)}>
                        <figure>
                          {" "}
                          <img src={memberteam63} alt="" />{" "}
                        </figure> 
                        <figcaption>
                          <div className="member-boxdown">
                            <h4> Andy Egli </h4>
                            <h5> Football Expert</h5>
                          </div>
                          <span className="left-arrow">
                            {" "}
                            <img src={arrowIcon} alt="" />{" "}
                          </span>{" "}
                          <div className="memberInfo">
                           {/*  <p>
                              <a>
                                Read More &nbsp;{" "}
                                <span className="right-arrow">
                                  {" "}
                                  <img src={arrowIcon} alt="" />{" "}
                                </span>{" "}
                              </a>
                            </p>*/}
                            <p>
                              <a target="blank" href="">
                                <img src={linkedInIcon} />
                              </a>
                            </p>
                          </div>
                        </figcaption>
                         </div>
                       </li>

                     </div>












              </OwlCarousel>

            </ul>
          </div>
        </div>

        <div className="bluebox" >
          <div className="container">
            <div className="blueboxinn">
              <div className="row">
                <div className="col-lg-2"> </div>
                <div className="col-lg-7">
                  <div className="blu">
                    <h3> We are Waiting for You! </h3>
                  </div>
                </div>
                <div className="col-lg-3">
                  <button
                    className="button button-blue"
                    onClick={showJoinModal}
                  >
                    JOIN NOW
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OurTeam;