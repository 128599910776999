import React, { useState } from 'react';
import validator from 'validator';
import { registerByEmail } from '../../api/webApiControl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from 'react-fullscreen-loading';
import ReCAPTCHA from "react-google-recaptcha";
import { webConstants } from "../../utils/webConstants";


import frame from "../../assets/images/register-frame.png";






function Register() {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [recaptchaError, setRecaptchaError] = useState('');
    const [recaptchaValue, setRecaptchaValue] = useState('');

    let windowUrl = window.location.href;
    let url = new URL(windowUrl);
    let referralCode = url.searchParams.get("referralCode");
    const recaptchaRef = React.createRef();


    const onCaptchaChange = (event) => {
        console.log({ event });
        if (validator.isEmpty(event)) {
            setRecaptchaError("Please verify the captcha");
        } else {
            setRecaptchaError("");
            setRecaptchaValue(event);

        }
    }



    const onValueChange = (event) => {
        if (validator.isEmpty(event.target.value) || (!validator.isEmail(event.target.value))) {
            setEmailError("Please enter valid email");
        } else {
            setEmailError("");
        }
        setEmail(event.target.value)
    }

    const validateEmail = async (event) => {
        event.preventDefault();
        if (validator.isEmpty(email) || !validator.isEmail(email)) {
            setEmailError("Please enter the email");
        } else if (validator.isEmpty(recaptchaValue)) {
            setRecaptchaError("Please verify the captcha");
        } else {
            if (recaptchaRef.current) {
                recaptchaRef.current.reset();
            }

            setRecaptchaError("");
            setEmailError("");
            setLoading(true);
            const response = await registerByEmail({ email, referral_code: referralCode, recaptcha: recaptchaValue });
            setLoading(false);
            if (response.data.statusCode === 200) {
                document.getElementById('close_btn_register').click();
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        }

    }

    return (
        <div>
            <ToastContainer />
            <div>
                <Loading loading={loading} background="rgba(0, 70, 184, 0.6)" loaderColor="#3498db" />
            </div>





            <div className="modal fade show" id="successpop" data-bs-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" style={{ "paddingRight": "17px" }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content popcontent succesmainmain">

                        <button type="button" className="btn-close" id='close_btn_register_success'
                            data-bs-dismiss="modal" aria-label="Close"></button>

                        <div className="modal-body succesmaininn">
                            <div className='sec-box'>
                                <h5 className="modal-title" id="exampleModalLabel">Success!</h5>

                                <div className='succesmaininnmid'>

                                    <p>Email verification link has been sent to your email. If you cannot see it in few seconds, please check your spam folder or search for an email </p>



                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>




            <div className="modal fade show" id="errorpop" data-bs-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" style={{ "paddingRight": "17px" }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content popcontent succesmainmain ">

                        <button type="button" className="btn-close" id='close_btn_register_error'
                            data-bs-dismiss="modal" aria-label="Close"></button>

                        <div className="modal-body succesmaininn errorred">
                            <div className='sec-box'>
                                <h5 className="modal-title" id="exampleModalLabel">Error! </h5>

                                <div className='succesmaininnmid'>

                                    <p>Email verification link has been sent to your email. If you cannot see it in few seconds, please check your spam folder or search for an email </p>



                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade show" id="staticBackdrop" data-bs-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" style={{ "paddingRight": "17px" }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content popcontent lightbg">
                        <button type="button" className="btn-close" id='close_btn_register' data-bs-dismiss="modal" aria-label="Close"></button>
                        <div className="modal-body popcontentinn">

                            <div className="framregmain">
                                <div className="row d-flex justify-content-center align-items-center">

                                    <div className="col-md-8">


                                        <div className='sec-box'>
                                            <h5 className="modal-title" id="exampleModalLabel">Register</h5>

                                            <span className="rareassets">Register and get special Metabloqs NFT Assets*.</span>


                                            <div className='bigsecinputnew secbigsecinputnew'>
                                                <form onSubmit={validateEmail}>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <span className="inputboxupp">
                                                                <input type="email" name="email" required="" className="form-control reginput" id="inputEmail" placeholder="Email address" onChange={(event) => onValueChange(event)} />

                                                            </span>
                                                            <div className='text-danger errorebox'>
                                                                {emailError ? (<label>
                                                                    Please enter valid email
                                                                </label>) : ''}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <div className="captchasec">

                                                                <ReCAPTCHA
                                                                    ref={recaptchaRef}
                                                                    sitekey={webConstants.RECAPTCHA.SITE_KEY}
                                                                    onChange={onCaptchaChange}
                                                                />

                                                            </div>

                                                        </div>

                                                        <div className='text-danger text-center'>


                                                            {recaptchaError ? (<label>
                                                                {recaptchaError}
                                                            </label>) : ''}

                                                        </div>
                                                    </div>
                                                </form>




                                                <p className="reviewingtxt">*Note: After reviewing your application, we will inform you if you are eligible </p>


                                                <p>By clicking apply, you agree to our
                                                    <a href="https://static.metabloqs.com/public/Airdrop_Terms_and_Conditions.pdf" rel="noreferrer" target="_blank"> Terms and Conditions</a>
                                                </p>




                                            </div>
                                        </div>

                                        <button type="button" className="btn btn-primary applybtn" onClick={validateEmail}>
                                            Apply Now </button>




                                    </div>



                                    <div className="col-md-4">

                                        <div className="framebg">

                                            <img src={frame} />

                                        </div>




                                    </div>





                                </div>


                            </div>






                        </div>
                    </div>
                </div>
            </div>











        </div>

    )
}

export default Register;