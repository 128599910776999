import DefaultLayout from "../layouts/DefaultLayout";
import React, { useState, useEffect } from "react";





import wallet1 from "../assets/images/guarda-wallet.png";
import wallet2 from "../assets/images/freewallet.png";
import wallet3 from "../assets/images/crypterium-wallet.png";
import wallet4 from "../assets/images/xcelpay.png";
import wallet5 from "../assets/images/lumi-wallet.png";
import wallet6 from "../assets/images/dcent-wallet.png";
import wallet7 from "../assets/images/bitfi.png";
import wallet8 from '../assets/images/ellipal-wallet.png'
import wallet9 from "../assets/images/trezor-wallet.png";
import wallet10 from "../assets/images/simplehold-wallet.png";



import walletssupporting1 from "../assets/images/google-play.png";
import walletssupporting2 from "../assets/images/xdc-tok.png";
import walletssupporting3 from "../assets/images/big-xin.png";




function SupportedWallets() {
    return (
        <DefaultLayout>
<div className="walletmain">
  <div className="container">
    <div className="row">
      <div className="starter-template">
        <h4>Wallets Supporting XDC</h4>
        
        
        <ul className="list-unstyled user-cards">
          <li className="user-card-designer"> <a href="https://play.google.com/store/apps/details?id=com.xdcwallet" target="_blank">
            <div className="user-card-intro">
              <h3>Open Source Wallet (Android App)</h3>
            </div>
            <div className="user-svg-wrapper"> <img src={walletssupporting1} alt="" /> </div>
            <span className="user-card-circle"></span> </a> </li>
          <li className="user-card-team"> <a href="https://xinfin.network/#webWallet" target="_blank">
            <div className="user-card-intro">
              <h3>XDC Web Wallet</h3>
            </div>
            <div className="user-svg-wrapper"> <img src={walletssupporting2} alt="" /> </div>
            <span className="user-card-circle"></span> </a> </li>
          <li className="user-card-developer"> <a href="https://chrome.google.com/webstore/detail/xdcpay/bocpokimicclpaiekenaeelehdjllofo?hl=en" target="_blank">
            <div className="user-card-intro">
              <h3>XinPay (XDC Extension Wallet)</h3>
            </div>
            <div className="user-svg-wrapper"> <img src={walletssupporting3} alt="" /> </div>
            <span className="user-card-circle"></span> </a> </li>
        </ul>
        
        
      </div>
    </div>
    <div className="row">
      <figure className="snip1568">
        <figcaption>
          <h3>Guarda Wallet</h3>
          <div className="cont">
            <div className="grid">
              <div className="col"><a href="https://guarda.co/app/" target="_blank">Web</a> </div>
              <div className="col"><a href="https://play.google.com/store/apps/details?id=com.crypto.multiwallet" target="_blank">Android</a> </div>
              <div className="col"><a href="https://apps.apple.com/app/guarda-wallet/id1442083982" target="_blank">iOS</a> </div>
              <div className="col"><a href="https://guarda.com/desktop/" target="_blank">MacOS</a> </div>
              <div className="col"><a href="https://guarda.com/desktop/" target="_blank">Windows</a> </div>
              <div className="col"><a href="https://guarda.com/desktop/" target="_blank">Ubuntu</a> </div>
              <div className="col"><a href="https://guarda.com/chrome-extension/" target="_blank">Chrome</a> </div>
            </div>
          </div>
        </figcaption>
        <div className="profile-image"><a href="https://guarda.com/"> <img src={wallet1} alt="" /></a> </div>
      </figure>
      
      <figure className="snip1568">
        <figcaption>
          <h3>Freewallet</h3>
          <div className="cont">
            <div className="grid">
              <div className="col"><a href="https://app.freewallet.org/auth/registration" target="_blank"></a>Web</div>
              <div className="col"><a href="https://play.google.com/store/apps/details?id=mw.org.freewallet.app" target="_blank"></a>Android</div>
              <div className="col"><a href="https://apps.apple.com/us/app/id1274003898?mt=8" target="_blank"></a>iOS</div>
            </div>
          </div>
        </figcaption>
        <div className="profile-image"><a href="https://freewallet.org/" target="_blank"><img src={wallet2} alt="" /></a>  </div>
      </figure>
      
      
      <figure className="snip1568">
        <figcaption>
          <h3>Crypterium Wallet</h3>
          <div className="cont">
            <div className="grid">
              <div className="col"><a href="https://app.crypterium.com/sign-up" target="_blank"></a>Web</div>
              <div className="col"><a href="https://play.google.com/store/apps/details?id=com.crypterium" target="_blank"></a>Android</div>
              <div className="col"><a href="https://apps.apple.com/app/crypterium-bitcoin-wallet/id1360632912" target="_blank"></a>iOS</div>
            </div>
          </div>
        </figcaption>
        <div className="profile-image"><a href="https://crypterium.com/" target="_blank"><img src={wallet3} alt="" /></a>  </div>
      </figure>
      
      
      <figure className="snip1568">
        <figcaption>
          <h3>XcelPay</h3>
          <div className="cont">
            <div className="grid">
              <div className="col"><a href="https://play.google.com/store/apps/details?id=com.XcelTrip.XcelPay" target="_blank"></a>Android</div>
              <div className="col"><a href="https://apps.apple.com/us/app/xcelpay-secure-crypto-wallet/id1461215417" target="_blank"></a>iOS</div>
            </div>
          </div>
        </figcaption>
        <div className="profile-image"><a href="https://xcelpay.io/" target="_blank"><img src={wallet4} alt="" /></a>  </div>
      </figure>
      
      
      <figure className="snip1568">
        <figcaption>
          <h3>Lumi Wallet</h3>
          <div className="cont">
            <div className="grid">
              <div className="col"><a href="https://web.lumiwallet.com/" target="_blank"></a>Web</div>
              <div className="col"><a href="https://play.google.com/store/apps/details?id=com.lumiwallet.android" target="_blank"></a>Android</div>
            </div>
          </div>
        </figcaption>
        <div className="profile-image"><a href="https://lumiwallet.com/" target="_blank"><img src={wallet5} alt="" /></a>  </div>
      </figure>
      
      
      <figure className="snip1568">
        <figcaption>
          <h3>D'CENT Wallet</h3>
          <div className="cont">
            <div className="grid">
              <div className="col"><a href="https://play.google.com/store/apps/details?id=com.kr.iotrust.dcent.wallet&utm_source=dcent_medium&utm_campaign=xdc_support" target="_blank"></a>Android</div>
              <div className="col"><a href="https://apps.apple.com/kr/app/dcent-hardware-wallet/id1447206611" target="_blank"></a>iOS</div>
              <div className="col"><a href="https://dcentwallet.com/products/BiometricWallet" target="_blank"></a>Hardware</div>
            </div>
          </div>
        </figcaption>
        <div className="profile-image"><a href="https://dcentwallet.com/products/BiometricWallet" target="_blank"><img src={wallet6} alt="" /></a>  </div>
      </figure>
      
      <figure className="snip1568">
        <figcaption>
          <h3>Bitfi Wallet</h3>
          <div className="cont">
            <div className="grid">
              <div className="col"><a href="https://bitfi.com/order" target="_blank"></a>Hardware</div>
            </div>
          </div>
        </figcaption>
        <div className="profile-image"><a href="https://bitfi.com" target="_blank"><img src={wallet7} alt="" /></a>  </div>
      </figure>
      
      
      <figure className="snip1568">
        <figcaption>
          <h3>Ellipal Wallet</h3>
          <div className="cont">
            <div className="grid">
              <div className="col"><a href="https://www.ellipal.com/" target="_blank"></a>Hardware</div>
            </div>
          </div>
        </figcaption>
        <div className="profile-image"><a href="https://www.ellipal.com/" target="_blank"> <img src={wallet8} alt="" /></a> </div>
      </figure>
      
      
      <figure className="snip1568">
        <figcaption>
          <h3>Trezor Wallet</h3>
          <div className="cont">
            <div className="grid">
              <div className="col"><a href="https://trezor.io/" target="_blank"></a>Hardware</div>
            </div>
          </div>
        </figcaption>
        <div className="profile-image"><a href="https://trezor.io/" target="_blank"><img src={wallet9} alt="" /></a>  </div>
      </figure>
      
      
      <figure className="snip1568">
        <figcaption>
          <h3>SimpleHold Wallet</h3>
          <div className="cont">
            <div className="grid">
              <div className="col"><a href="https://simplehold.io/" target="_blank"></a>Browser Extension Wallet</div>
            </div>
          </div>
        </figcaption>
        <div className="profile-image"><a href="https://simplehold.io/" target="_blank"><img src={wallet10} alt="" /></a>  </div>
      </figure>
      
    </div>
  </div>
</div>



    </DefaultLayout>
    );
}
export default SupportedWallets;
