export const webConstants = {
    API: {
        SERVER_URL: process.env.REACT_APP_SERVER_URL    // "https://metabloqs.com/api/v1/",
        //    SERVER_URL: "https://dev.metabloqs.com/api/v1/",
        //  SERVER_URL: "http://localhost:3001/api/v1/",
    },
    RECAPTCHA: {
        // SITE_KEY:"6LcL7aQfAAAAAIG_h5qaEhbR__-11PzT5r67iL2K" // dev key
        SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY      //"6Lc37KQfAAAAAPdhNWWruBK9LRafCInFBWF00VGD" // prod key
    }
}