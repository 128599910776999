import axios from "axios";
import { destroyToken, getToken } from "../services/jwt_service";
import { webConstants } from "../utils/webConstants";

const api = axios.create({
    baseURL: webConstants.API.SERVER_URL,
});

// Add a request interceptor
api.interceptors.request.use(
    (request) => {
        const token = getToken();
        request.headers = { Authorization: token };
        return request;
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
api.interceptors.response.use(
    (response) => {
        if(response.data.statusCode === 401){
            destroyToken();
            window.location.href = "/";
        } else {
            return response;
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);



export const registerByEmail = (data) => {
    console.log(webConstants.API.SERVER_URL)
    return api.post("user/registerEmail", data);
};


export const getUserDataByToken = (token) => {
    return api.post("user/verifyEmailToken",token)
};

export const register = (data) => {
    return api.post("user/register", data);
};


export const userLoginByCred = (data) => {
    return api.post(`user/login`,data);
}


export const getUserData = () => {
    return api.get(`user/getUserData`);
}


export const updateUser = (data) => {
    return api.post(`user/updateUser`,data);
}


export const countries = () => {
    return api.get(`user/countriesList`);
}

export const updateWalletAddress = (data) => {
    return api.post(`user/updateWalletAddress`,data);
}

export const forgotPassword= (data) => {
    return api.post(`user/forgotPassword`,data);
}

export const resetPassword= (data) => {
    return api.post(`user/resetPassword`,data);
}