import banner1 from "../../assets/images/hero-banner-1.png";
import playIcon from "../../assets/images/icons/play-icon.svg";
import * as bootstrap from "bootstrap";
import { useNavigate } from "react-router-dom";

import React, { useState } from 'react';
import validator from 'validator';
import { registerByEmail } from '../../api/webApiControl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from "react-fullscreen-loading";
import { getToken } from "../../services/jwt_service";
import ReCAPTCHA from "react-google-recaptcha";
import { webConstants } from "../../utils/webConstants";

function HeroBanner() {
  const navigate = useNavigate();
  const recaptchaRef = React.createRef();


  function pauseVideo() {
    document.getElementById('trailerVideo').pause()
  }







var i=0;
window.addEventListener('scroll', (event) => {

  let scrollTop = window.scrollY;

   //console.log(scrollTop);
   var heroBannner = document.getElementById("hero-bannner");


   heroBannner.style.width = (100 - scrollTop/40)  + "%";
   heroBannner.style.top= -(scrollTop/10)  + "%";
   heroBannner.style.borderRadius= i + "px";

   if(i<25){
    i++;
   }

   if(scrollTop==0){
     heroBannner.style.borderRadius= "0px";
   }
  

});








  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [recaptchaError, setRecaptchaError] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState('');

  let windowUrl = window.location.href;
  let url = new URL(windowUrl);
  let referralCode = url.searchParams.get("referralCode");

  const onCaptchaChange = (event) => {
    console.log({event});
    if (validator.isEmpty(event)) {
      setRecaptchaError("Please verify the captcha");
    } else {
      setRecaptchaError("");
      setRecaptchaValue(event);

    }
  }

  const onValueChange = (event) => {
    if (validator.isEmpty(event.target.value) || (!validator.isEmail(event.target.value))) {
      setEmailError("Please enter valid email");
    } else {
      setEmailError("");
    }
    setEmail(event.target.value);
  }

  function showJoinModal() {
    if (getToken()) {
      navigate('/profile');
    } else {
      let myModal = new bootstrap.Modal(document.getElementById('staticBackdrop'), {});
      myModal.show()
    }
  }

  const validateEmail = async (event) => {
    event.preventDefault();
    console.log({ recaptchaValue });
    if (validator.isEmpty(email) || !validator.isEmail(email)) {
      setEmailError("Please enter the email");
    } else if (validator.isEmpty(recaptchaValue)) {
      console.log(recaptchaValue)
      setRecaptchaError("Please verify the captcha");
    } else {
      if(recaptchaRef.current){
        recaptchaRef.current.reset();
      }

      setRecaptchaError("");
      setEmailError("");
      setLoading(true);
      const response = await registerByEmail({ email, referral_code: referralCode, recaptcha: recaptchaValue });
      setRecaptchaValue('');
      setLoading(false);
      if (response.data.statusCode === 200) {
        setEmail("");
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    }

  }

  return (
    <>
      <ToastContainer />
      <div>
        <Loading loading={loading} background="rgba(0, 70, 184, 0.6)" loaderColor="#3498db" />
      </div>
      <section className="hero-bannner" id="hero-bannner" style={{ backgroundImage: banner1 }} >
        <div className="container">
          <div className="topheader">
            <div className="row">
              <div className="banner-text">
                <h1>The fastest growing & <br/> most trusted metaverse</h1>
                <p>Metabloqs has now over 250,000 citizens registered. Thank you! <br/> tokens to be on sale soon</p>
                <button className="play-btn" data-bs-toggle="modal" data-bs-target="#trailerVideoModal">Watch Trailer <img src={playIcon} alt="" /></button>
                <button className="button button-blue" onClick={showJoinModal}>Join Now</button>
              </div>
            </div>
          </div>
        </div>
      </section>

 



      <div className="modal fade show watchvideomain" id="trailerVideoModal" data-bs-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" style={{ "paddingRight": "17px" }} aria-modal="true" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-xl no-margin">
          <div className="modal-content popcontent watchvideomaininn">
            <button type="button" className="btn-close" id='close_btn' onClick={pauseVideo} data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="modal-body popcontentinn videoinn">
              <iframe style={{ height: "100vh" }} width="100%" id="trailerVideo" muted autoPlay={true} src="https://www.youtube.com/embed/ZXcKvVX5C8Y" controls >
              </iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroBanner;