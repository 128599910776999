import DefaultLayout from "../layouts/DefaultLayout";
import React, { useState, useEffect, useCallback } from "react";
import { getUserData, countries } from "../api/webApiControl";
import { updateUser } from "../api/webApiControl";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "react-fullscreen-loading";
import { Link } from "react-router-dom";

import social1 from "../assets/images/icons/linkedin.png";
import social2 from "../assets/images/icons/discord.png";
import social3 from "../assets/images/icons/bear.png";
import social4 from "../assets/images/icons/Medium.png";
import social5 from "../assets/images/icons/Twitter.png";
import social6 from "../assets/images/icons/Facebook.png";
import social7 from "../assets/images/icons/Instagram.png";
import telegramIcon from '../assets/images/icons/telegram.png'
import edit_icon from "../assets/images/icons/edit_icon.png";
import copy_icon from "../assets/images/icons/copy_icon.png";


import copy_arrow from "../assets/images/icons/arrow-white.png";



import arrowIcon from "../assets/images/icons/left-arrow.svg";


import banefitsImg5 from "../assets/images/banefits-img-5.png";


import referal1 from "../assets/images/referal-code.png";
import referal2 from "../assets/images/referal-link.png";

import copy_traced1 from "../assets/images/user-traced.png";

import copy_traced2 from "../assets/images/invite-traced.png";

import copy_traced3 from "../assets/images/star-traced.png";

import copy_traced4 from "../assets/images/logout-traced.png";







import soonbg from "../assets/images/coming-bg.jpg";
import { destroyToken } from "../services/jwt_service";

function Profile() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let userData = {
    username: "",
    password: "",
    confirmPassword: "",
    first_name: "",
    last_name: "",
    email: "",
    dob: "",
    country: "",
    referral_code: "",
    wallet_address: "",
  };
  const [user, setUser] = useState(userData);
  const [countriesList, setCountriesList] = useState([]);
  let [userReferralCode, setUserReferralCode] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    getUserData()
      .then((res) => {
        if (res.data.statusCode === 200) {
          setUser({
            ...res.data.data,
          });
          setUserReferralCode('https://metabloqs.com/?referralCode=' + res.data.data.referral_code);


        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });

    countries().then((res) => {
      if (res.data.statusCode === 200) {
        setCountriesList(res.data.data);
      } else {
        toast.error(res.data.message);
      }
    }).catch((err) => {
      console.log(err);
      toast.error(err);
    })
  }, []);

  const updateUserData = async (e) => {
    e.preventDefault();

    setLoading(true);
    let res = await updateUser(user);
    setLoading(false);
    if (res.data.statusCode === 200) {
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
    }
  }

  const onValueChange = (e) => {
    if (e.target.name === "username") {
      e.target.value = e.target.value.toLowerCase().trim();
    }

    setUser({ ...user, [e.target.name]: e.target.value });
  };

  function logout() {
    destroyToken();
    navigate('/login');
  }

  const enableEdit = useCallback((field) => () => {
    document.getElementById('loginbtnn').style.display = 'block';
    document.getElementById((field + '_field')).disabled = false;
  }, [])

  return (
  <DefaultLayout>
  <ToastContainer />
  <div>
      <Loading loading={loading} background="#0046B8" loaderColor="#3498db" />
    </div>
  <div className="walletmain">
      <div className="container">
      <div className="row d-flex align-items-start">
          <div className="col-md-3">
          <div className="leftprofile">
              <div className="nav flex-column nav-pills leftprofilebtninn" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <h2 className="user-newpro">{user.first_name +" " +user.last_name}</h2>
              <button className="nav-link active" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false"> <img src={copy_traced1} /> <span> Invite your Friends </span> <i> <img src={copy_arrow} /> </i> </button>
              {
              user.status === 1 &&
              <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false"> <img src={copy_traced2} /> <span>My Account</span> <i> <img src={copy_arrow} /></i> </button>
              }
              <button className="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false"> <img src={copy_traced3} /> <span>Rewards</span> <i> <img src={copy_arrow} /></i> </button>
              <button className="nav-link bottomlogout" onClick={logout}> <img src={copy_traced4} /> <span>Logout</span> <i> <img src={copy_arrow} /></i> </button>
            </div>
            </div>
        </div>
          <div className="col-md-9">
          <div className="rightprofile">
              <div className="tab-content rightwalletbox" id="v-pills-tabContent">
              <div className="tab-pane fade show active" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
               
                  <div className="code codeonetop newtopcode">

                   <div className="codeonetopinn">

                  <h5>Congratulations!</h5>




                  <h1>You're one of the first Metabloqs citizens. Congratulations! </h1>
                  <p>While we're building our meta-world, why don't you invite some friends to earn tokens? Thank you for your trust on us </p>
               
                 

                   </div>
                </div>

                <div className="row">

                    <div className="col-md-6">
                  <Link
                className="banefits-box banefits-box-type-2 earn-bane"
                to="/"
                style={{ backgroundImage: `url(${banefitsImg5})` }} >
                  <div className="box-text">
                      <div className="blackboxtxtinn">
<h3> Invite and Earn</h3>
                      <h4> 5 XDC Tokens </h4>

                      
                    </div>
                    </div>
                  </Link>

                </div>

                <div className="col-md-6">



                     <div className="code earncode">

                     <h3>Refer and Earn </h3>



                     <ul className="reffcode">

                           <li>

                           <div className="codeleft">

                            <img src={referal1} alt="" />

                           </div>


                           <div className="coderight">

                           <span>Referal Code</span>
                            <h2> {user.referral_code}</h2>
                           </div>



                           </li>




                            <li>


                            <div className="codeleft">

                            <img src={referal2} alt="" />

                           </div>


                           <div className="coderight">

                           <span>Referal Link</span>

                            <a target="_blank" href={userReferralCode}>{userReferralCode}</a>
                             <span className="copytxt" onClick={() =>
                              {navigator.clipboard.writeText(userReferralCode);
                               toast.success('Copied Successfully')}}>

                                <img src={copy_icon} alt="copy icon" width="25" height="25" />
                                 </span>
                         
                           </div>


                            </li>


                     </ul>

{/* 
 

                 
                 <h2> Your Referral Code: {user.referral_code}</h2>
                  <span>Your referral link: </span> 
                 <a target="_blank" href={userReferralCode}>{userReferralCode}</a>
                  <span className="copytxt" onClick={() => {navigator.clipboard.writeText(userReferralCode); 
                    toast.success('Copied Successfully')}}> <img src={copy_icon} alt="copy icon" width="25" height="25" />
                     </span>

*/}  

                
                </div>


 <div className="code earncode">
                 <ul className="footer-social codelsocial">
                      <li><a href={`mailto:?subject=Be a Metabloqs resident!!!&body=Hello!
                  Free NFTs? Yes. You can get it too. I just secured my NFTs from Metabloqs here:  ${userReferralCode}`} target="_blank"
                          title="Share by Email"> <img src="http://png-2.findicons.com/files/icons/573/must_have/48/mail.png"></img> </a></li>
                      <li> <a href={"https://www.linkedin.com/sharing/share-offsite/?url=" + userReferralCode} target="_blank"> <img src={social1} alt="" /></a> </li>
                      <li> <a href={"https://twitter.com/intent/tweet?text=" +" Free NFTs? Yes. You can get it too. I just secured my NFTs from Metabloqs \n" +userReferralCode} target="_blank"> <img src={social5} alt="" /></a> </li>
                      <li> <a href={"https://www.facebook.com/sharer/sharer.php?u=" + userReferralCode} target="_blank"> <img src={social6} alt="" /></a> </li>
                    </ul>


                    </div>



                </div>



                </div>



              


             {/*   <div className="code">
                  <h1> INVITE YOUR FRIENDS AND EARN UPTO 5 $XDC TOKENS RIGHT NOW!</h1>
                  <h2> Your Referral Code: {user.referral_code}</h2>
                  <span>Your referral link: </span> <a target="_blank" href={userReferralCode}>{userReferralCode}</a> <span className="copytxt" onClick={() => {navigator.clipboard.writeText(userReferralCode); toast.success('Copied Successfully')}}> <img src={copy_icon} alt="copy icon" width="25" height="25" /> </span>
                  <p>Share the excitement by referring friends to Metabloqs and get Premium NFT and upto 10 XDC tokens for the top 1000 referrers. </p>
                  <ul className="footer-social codelsocial">
                      <li><a href={`mailto:?subject=Be a Metabloqs resident!!!&body=Hello!
                  Free NFTs? Yes. You can get it too. I just secured my NFTs from Metabloqs here:  ${userReferralCode}`} target="_blank"
                          title="Share by Email"> <img src="http://png-2.findicons.com/files/icons/573/must_have/48/mail.png"></img> </a></li>
                      <li> <a href={"https://www.linkedin.com/sharing/share-offsite/?url=" + userReferralCode} target="_blank"> <img src={social1} alt="" /></a> </li>
                      <li> <a href={"https://twitter.com/intent/tweet?text=" +" Free NFTs? Yes. You can get it too. I just secured my NFTs from Metabloqs \n" +userReferralCode} target="_blank"> <img src={social5} alt="" /></a> </li>
                      <li> <a href={"https://www.facebook.com/sharer/sharer.php?u=" + userReferralCode} target="_blank"> <img src={social6} alt="" /></a> </li>
                    </ul>
                </div>*/} 



                </div>




              <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                  <div className="tableback">
                  <table className="table-fill">
                      <tbody className="table-hover nocolor_table">
                      <tr>
                          <td className="text-left">Username:</td>
                          <td className="text-left"><input type="text" className="form-control" name="username" required value={user.username} disabled id="username_field" onChange={(e) =>
                          onValueChange(e)} /> </td>
                          <td className="text-left "><div className="round-blue"> <img src={edit_icon} alt="" width="25" height="25" onClick={enableEdit('username')} /> </div></td>
                        </tr>
                    <br>
                        </br>
                      
                      <tr>
                      <td className="text-left">First Name:</td>
                      <td className="text-left"><input type="text" name="first_name" disabled required className="form-control" id="first_name_field"
                              placeholder="First Name" value={user.first_name}
                              onChange={(e) =>
                          onValueChange(e)} /> </td>
                      <td className="text-left"><div className="round-blue"> <img src={edit_icon} alt="" width="25" height="25" onClick={enableEdit('first_name')} /> </div></td>
                    </tr>
                      <br>
                        </br>
                      
                      <tr>
                      <td className="text-left">Last Name: </td>
                      <td className="text-left"><input type="text" disabled name="last_name" required className="form-control" id="last_name_field" placeholder="Last Name"
                              value={user.last_name}
                              onChange={(e) =>
                          onValueChange(e)} /></td>
                      <td className="text-left"><div className="round-blue"> <img src={edit_icon} alt="" width="25" height="25" onClick={enableEdit('last_name')} /> </div></td>
                    </tr>
                      <br>
                        </br>
                      
                      <tr>
                      <td className="text-left">Email ID:</td>
                      <td className="text-left"><input type="email" disabled name="email" required className="form-control" id="email_field" placeholder="E-mail ID"
                              value={user.email}
                              onChange={(e) =>
                          onValueChange(e)} /></td>
                      <td></td>
                    </tr>
                      <br>
                        </br>
                      
                      <tr>
                      <td className="text-left">Country:</td>
                      <td className="text-left"><select disabled id="country_field" name="country" required="required" className="form-control bfh-countries" data-country="US" value={user.country}
                              onChange={(e) =>
                          
                           onValueChange(e)}>
                          <option value="Select Country">Select Country</option>
                          
                          
                              {countriesList.length > 0
                                && countriesList.map((item, i) => {
                                  return (
                                    
                          
                          <option key={i} value={item.sortname}>{item.name}</option>
                          
                          
                                  )
                                }, this)}
                            
                        
                        </select></td>
                      <td className="text-left"><div className="round-blue"> <img src={edit_icon} alt="" width="25" height="25" onClick={enableEdit('country')} /> </div></td>
                    </tr>
                      <br>
                        </br>
                      
                      <tr>
                      <td className="text-left">Referred Code:</td>
                      <td className="text-left"><input type="text" disabled name="referral_code" className="form-control" id="inputEmail" placeholder="Referral Code (Optional)" value={user.referral_code}
                              onChange={(e) =>
                          onValueChange(e)} /></td>
                      <td></td>
                    </tr>
                      <br>
                        </br>
                      
                      <tr>
                      <td className="text-left">Wallet Address:</td>
                      <td className="text-left"> {user.wallet_address ? user.wallet_address :
                          <Link to="/createWallet">
                          Click to make a new wallet.
                          </Link>
                          } </td>
                      <td className="text-left"><div className="round-blue">
                          <Link to="/createWallet">
                          <img src={edit_icon} alt="" width="25" height="25" />
                          </Link>
                        </div></td>
                    </tr>
                        </tbody>
                      
                    </table>
                  <div className="col-md-12">
                      <div className="profilebtnbox">
                      <p><strong>*Note</strong>: After reviewing your application, we will inform you if you are eligible.</p>
                      <button style={{display:'none'}} className="apply-now" id="loginbtnn" onClick={updateUserData}>Save<span id="waitID" className="loader-4"> </span></button>
                    </div>
                    </div>
                </div>
                </div>
              <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                  <div className="code codeonetop">
                  <h2 style={{"fontSize":"20px","lineHeight": 1}}> Just Registration - Get - Standard NFT as a Reward <br>
                      </br>
                      <br>
                      </br>
                      Registration + 1 Referred Friend - Get - Standard NFT + 5 XDC Tokens <br>
                      </br>
                      <br>
                      </br>
                      Registration and Multiple Referred Friends (If among top 1000 Referrers) - Get - Premium NFT & 10 XDC Tokens in Total </h2>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>
</DefaultLayout>
);

}

export default Profile; 