import brandLogo from "../../assets/images/brand-logo/logo.png";
import brandLogo1 from "../../assets/images/brand-logo/logo-black.png";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as bootstrap from "bootstrap";

function Header() {
    function showJoinModal() {
        let myModal = new bootstrap.Modal(document.getElementById('staticBackdrop'), {});
        myModal.show()
    }
    const [isActive, setActive] = useState("true");

    const handleToggle = () => {
        setActive(!isActive);
    };

    useEffect(() => {
        window.onscroll = function () { myFunction() };
        var header = document.getElementById("myHeader");
        var sticky = header.offsetTop;
        function myFunction() {
            if (window.pageYOffset > sticky) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        }
    }, []);

    return (
        <header className="registerheader" id="myHeader">
            <div className="container">
                <div className="row">
                    <div className="topheaderone">
                        <div className="brand-logo">
                            <Link to="/">
                                <img className="whitelogo" src={brandLogo} alt="brand-logo" />
                                <img className="blacklogo" src={brandLogo1} alt="brand-logo" />
                            </Link>
                        </div>
                        <nav className="main-navigation">
                            <ul>
                                <li>
                                    <a href="https://static.metabloqs.com/public/Metabloqs_Whitepaper.pdf" rel="noreferrer" target="_blank">White Paper</a>
                                </li>
                                <li>
                                    <a href="#eco">Ecosystem</a>
                                </li>
                                <li>
                                    <a href="#team" >Team</a>
                                </li>
                            </ul>
                        </nav>
                        {!localStorage.getItem('userJwtToken') ?
                            <div className="header-right btngrajoin">
                                <a className="button-file btnjoin" onClick={showJoinModal }>
                                    <i></i>
                                    Join Now
                                </a>
                                <Link className="button-file btn-login" to="/login">Login
                                    <i></i>
                                </Link>
                            </div> :
                            <div className="header-right btngrajoin">

                                <Link className="button-file btnaccount" to="/profile">My Account
                                    <i></i>
                                </Link>
                            </div>

                        }

                        <button className="toggle_btn" onClick={handleToggle}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;