export const setToken = (token) => {
    localStorage.setItem('userJwtToken', token);
}

export const getToken = () => {
    return localStorage.getItem('userJwtToken');
}

export const destroyToken = () => {
    localStorage.removeItem('userJwtToken');
}