import DefaultLayout from "../layouts/DefaultLayout";
import React, { useState, useEffect } from "react";
import { register, countries } from "../api/webApiControl";
import { useLocation } from "react-router";
import { getUserDataByToken } from "../api/webApiControl";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "react-fullscreen-loading";
import infobtn from "../assets/images/info.png";
import framereward from "../assets/images/frame-register-rewards.png";
import ReCAPTCHA from "react-google-recaptcha";
import {webConstants} from "../utils/webConstants";
import validator from 'validator';

import { Link } from "react-router-dom";
import { setToken } from "../services/jwt_service";



function Profile() {
  const [date, setDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [passwordNotMatchError, setPasswordNotMatchError] = useState('');
  const [recaptchaError, setRecaptchaError] = useState('');
  const recaptchaRef = React.createRef();

  const [errorMessage, setErrorMessage] = useState({
    username: null,
    password: null,
    confirmPassword: null,
    firstName: null,
    lastName: null,
    emailId: null,
    dob: null,
    country: null,
  });

  let userData = {
    username: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    emailId: "",
    dob: "",
    country: "",
    referredByCode: "",
    walletAddress: "",
  };



  const [user, setUser] = useState(userData);

  const location = useLocation();
  const navigate = useNavigate();

  let a = location.pathname.split("/");
  let token = a[2];
  useEffect(() => {
    getUserDataByToken({ token })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setUser({
            ...user,
            emailId: res.data.data.user.email,
            referredByCode: res.data.data.user.reffered_by_code,
          });
          console.log({ userData });
        } else {
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
      });

    countries().then((res) => {
      if (res.data.statusCode === 200) {
        setCountriesList(res.data.data);
      } else {
        toast.error(res.data.message);
      }
    }).catch((err) => {
      console.log(err);
    })

  }, []);

  let {
    username,
    password,
    confirmPassword,
    firstName,
    lastName,
    emailId,
    dob,
    country,
    referredByCode,
    walletAddress,
  } = user;


  const validateInputs = (e) => {
    let fieldName = e.target.name;
    let errorMessagesObj = {};
    if (errorMessage[fieldName] != null && !e.target.value || e.target.value == 'Select Country') {
      setErrorMessage({ ...errorMessage, [fieldName]: `* ${fieldName} is required.` })
    } else {
      setErrorMessage({ ...errorMessage, [fieldName]: "" })
    }

    let inputValidity = false;
    for (let index = 0; index < Object.entries(user).length; index++) {
      const element = Object.entries(user)[index];
      if (element[0] == 'referredByCode' || element[0] == 'walletAddress') {
        continue;
      } else if (element[1] == "" || element[1] == undefined || element[1] == null) {
        inputValidity = false;
        errorMessagesObj = { ...errorMessagesObj, [element[0]]: `* ${element[0]} is required.` };
        // break;
      } else {
        inputValidity = true;
      }
    }
    setErrorMessage(errorMessagesObj)
    return inputValidity;



  }



  useEffect(() => {
    checkPasswordEquality('');
  }, [user]);

  const checkPasswordEquality = (e) => {
    if (user.password !== user.confirmPassword) {
      setPasswordNotMatchError('Both Password and Confirm password must match')

    } else {
      setPasswordNotMatchError('');
    }
  }

  const onValueChange = (e) => {

    validateInputs(e);


    if (e.target.name === "username") {
      e.target.value = e.target.value.toLowerCase().trim();
    }

    setUser({ ...user, [e.target.name]: e.target.value });



  };

  const onSubmit = async (e) => {
    e.preventDefault();
    checkPasswordEquality(e);
    const recaptchaValue = recaptchaRef.current.getValue();
    if (validator.isEmpty(recaptchaValue)) {
      setRecaptchaError("Please verify the captcha");
    } else if (validateInputs(e) && passwordNotMatchError == '') {
      setLoading(true);
      let userObj = {
        token: token,
        username: username,
        first_name: firstName,
        last_name: lastName,
        dob: dob,
        country: country,
        password: password,
        wallet_address: walletAddress,
        referredByCode: referredByCode,
        recaptcha: recaptchaValue,
      };
      try {
        let result = await register(userObj);
        setLoading(false);
        if (result.data.statusCode === 200) {
          setToken(result.data.data.token);
          toast.success(result.data.message);
          navigate("/profile");
        } else {
          toast.error(result.data.message);
        }
      } catch (error) {
        setLoading(false);
        toast.error('Something went wrong');
        console.log(error);
      }
    }

  };

  return (
    <DefaultLayout>
      <ToastContainer />
      <div>
        <Loading loading={loading} background="#0046B8" loaderColor="#3498db" />
      </div>
      <section
        id="home"
        className="sect section1"
        data-section-name="home">
        <div>



          <div class="logingraytop" id=""><div class="logingray">
            <div className="container">
              <div className="row">
                <div className="yellowbox registermain">
                  <div className="sec-box">
                    <span className="boldi">Register to get Rewards!</span>

                    <p>Please fill in the information below</p>
                  </div>

                  <div className="bigsecinput rewardnew">

                    <div className="row d-flex justify-content-center align-items-center">

                      <div className="col-md-7">


                        <div className="rewardleft">

                          <img src={framereward} />


                        </div>





                      </div>




                      <div className="col-md-5">

                        <div className="login-form">
                          <div className="main-div">
                            <p
                              id="ajaxRes"
                              align="center"
                              style={{
                                color: "#28a745",
                                fontSize: "15px",
                                fontWeight: "bold",
                              }}
                            ></p>

                            <form id="applyForm" method="post" className="row noformeffect">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <span className="inputboxupp">
                                    <input
                                      type="name"
                                      name="username"
                                      required
                                      className="form-control"
                                      id="inputEmail"
                                      placeholder="Username"
                                      value={username}
                                      onChange={(e) => onValueChange(e)}
                                    />
                                  </span>

                                  <div className='text-danger'>
                                    {errorMessage.username ? (<label>
                                      * Username is required
                                    </label>) : ''}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <span className="inputboxupp">
                                    <input
                                      type="password"
                                      name="password"
                                      required
                                      className="form-control"
                                      id="inputEmail"
                                      placeholder="Password"
                                      autoComplete="new-password"
                                      value={password}
                                      onChange={(e) => onValueChange(e)}
                                    />
                                  </span>

                                  <div className='text-danger'>
                                    {errorMessage.password ? (<label>
                                      * Password is required

                                    </label>) : ''}

                                    {passwordNotMatchError ? (<label>
                                      {passwordNotMatchError}
                                    </label>) : ''}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <span className="inputboxupp">
                                    <input
                                      type="password"
                                      name="confirmPassword"
                                      required
                                      className="form-control"
                                      id="inputEmail"
                                      placeholder="Confirm Password"
                                      autoComplete="new-password"
                                      value={confirmPassword}
                                      onChange={(e) => onValueChange(e)}
                                    />
                                  </span>
                                  <div className='text-danger'>
                                    {errorMessage.confirmPassword ? (<label>
                                      * Confirm Password is required
                                    </label>) : ''}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <span className="inputboxupp">
                                    <input
                                      type="name"
                                      name="firstName"
                                      required
                                      className="form-control"
                                      id="inputEmail"
                                      placeholder="First Name"
                                      value={firstName}
                                      onChange={(e) => onValueChange(e)}
                                    />
                                  </span>
                                  <div className='text-danger'>
                                    {errorMessage.firstName ? (<label>
                                      * First Name is required
                                    </label>) : ''}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <span className="inputboxupp">
                                    <input
                                      type="name"
                                      name="lastName"
                                      required
                                      className="form-control"
                                      id="inputEmail"
                                      placeholder="Last Name"
                                      value={lastName}
                                      onChange={(e) => onValueChange(e)}
                                    />
                                  </span>

                                  <div className='text-danger'>
                                    {errorMessage.lastName ? (<label>
                                      * Last Name is required

                                    </label>) : ''}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="form-group">
                                  <span className="inputboxupp">
                                    <input
                                      type="email"
                                      disabled
                                      name="emailId"
                                      required
                                      className="form-control"
                                      id="inputEmail"
                                      placeholder="E-mail"
                                      value={emailId}
                                      onChange={(e) => onValueChange(e)}
                                    />
                                  </span>
                                  <div className='text-danger'>
                                    {errorMessage.emailId ? (<label>
                                      * Email is required

                                    </label>) : ''}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <span className="inputboxupp">
                                    <input
                                      type={`${date == true ? "date" : "text"}`} onFocus={() => setDate(true)}
                                      name="dob"
                                      required
                                      className="form-control"
                                      id="inputEmail"
                                      placeholder="Date of Birth"
                                      value={dob}
                                      onChange={(e) => onValueChange(e)}
                                    />
                                  </span>
                                  <div className='text-danger'>
                                    {errorMessage.dob ? (<label>
                                      * Date of Birth is required

                                    </label>) : ''}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <span className="inputboxupp">
                                    <select
                                      name="country"
                                      required="required"
                                      className="form-control bfh-countries"
                                      data-country="US"
                                      value={country}
                                      onChange={(e) => onValueChange(e)}
                                    >
                                      <option value="">
                                        Select Country
                                      </option>
                                      {countriesList.length > 0
                                        && countriesList.map((item, i) => {
                                          return (
                                            <option key={i} value={item.sortname}>{item.name}</option>
                                          )
                                        }, this)}
                                    </select>
                                  </span>
                                  <div className='text-danger'>
                                    {errorMessage.country ? (<label>
                                      * Country is required

                                    </label>) : ''}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="form-group">
                                  <span className="inputboxupp">
                                    <input
                                      type="text"
                                      name="referredByCode"
                                      className="form-control"
                                      id="inputEmail"
                                      placeholder="Reference code (Only if you have one else is not compulsory)"
                                      value={referredByCode}
                                      onChange={(e) => onValueChange(e)}
                                    />
                                  </span>


                                </div>
                              </div>
                              <div className="col-md-12 btninfomainleft">
                                <div className="form-group">
                                  <span className="inputboxupp">
                                    <input
                                      type="text"
                                      name="walletAddress"
                                      className="form-control"
                                      id="inputEmail"
                                      placeholder="Any XDC network supported wallets address such as XDC Web Wallet, XinPay, etc"
                                      value={walletAddress}
                                      onChange={(e) => onValueChange(e)}
                                    />
                                  </span>
                                </div>
                              </div>

                              <div className="col-md-1 btninfomain">
                                <div className="form-group btninfo">

                                  <Link to="/supportedWallets" target="_blank" >


                                    <img src={infobtn} alt="" />

                                  </Link>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div
                                  className="profilebtnbox"
                                  style={{ alignItems: "center" }}
                                >



                                  <p>By clicking save, you agree to our<a href="https://static.metabloqs.com/public/Airdrop_Terms_and_Conditions.pdf" rel="noreferrer" target="_blank"> Terms and Conditions</a></p>


                                  <button
                                    className="apply-now btn btn-info savebtn"
                                    id="apply"
                                    onClick={onSubmit}
                                  >
                                    Save
                                    <span
                                      id="waitID"
                                      style={{ display: "none" }}
                                      className="loader-4"
                                    >
                                      {" "}
                                    </span>
                                  </button>
                                </div>
                              </div>

                              <div className="col-md-12">
                                  <div className="form-group">
                                      <ReCAPTCHA
                                          ref={recaptchaRef}
                                          sitekey={webConstants.RECAPTCHA.SITE_KEY}

                                      />
                                  </div>

                                  <div className='text-danger erroremail'>

                                      {recaptchaError ? (<label>
                                          {recaptchaError}
                                      </label>) : ''}
                                  </div>
                                </div>



                            </form>


                          </div>
                        </div>



                      </div>

                      <p>
                        Participants/Citizens from the following countries are restricted due to legal reasons:
                        <br /> United States, Afghanistan, American Samoa, The Bahamas, Botswana, Democratic People's Republic of Korea, Ethiopia, Ghana, Guam, Iran, Iraq, Libya, Nigeria, Pakistan, Panama, Puerto Rico, Samoa, Saudi Arabia, Sri Lanka, Syria, Trinidad and Tobago, Tunisia, US Virgin Islands, Yemen.
                      </p>

                    </div>



                  </div>

                  <div className="mainvisual__scroll">
                    <div className="bar"></div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          </div>
        </div>
      </section>
    </DefaultLayout>
  );
}
export default Profile;
