import partnerLogo1 from "../../assets/images/partner-1.png";
import partnerLogo2 from "../../assets/images/partner-2.png";
import partnerLogo3 from "../../assets/images/partner-3.png";
import partnerLogo5 from "../../assets/images/partner-5.png";
import partnerLogo6 from "../../assets/images/partner-6.png";
import partnerLogo7 from "../../assets/images/partner-7.png";
import partnerLogo8 from "../../assets/images/partner-8.png";
import partnerLogo9 from "../../assets/images/partner-9.png";
import partnerLogo11 from "../../assets/images/partner-11.png";
import partnerLogo12 from "../../assets/images/partner-12.png";
import partnerLogo13 from "../../assets/images/partner-13.png";
import partnerLogo14 from "../../assets/images/partner-14.png";
import partnerLogo15 from "../../assets/images/partner-15.png";
import partnerLogo16 from "../../assets/images/partner-16.png";
import partnerLogo18 from "../../assets/images/partner-18.png";
import partnerLogo17 from "../../assets/images/partner-17.png";

function EcoSystem() {
    return (
        <section className="eco-system" id="eco">
            <div className="ecoinn">
                <h2>Ecosystem</h2>
                <div className="container">
                    <ul className="partner-list">
                        <li>
                            <img src={partnerLogo16} alt="" />
                        </li>
                        <li>
                            <img src={partnerLogo17} alt="" />
                        </li>
                        <li>
                            <img src={partnerLogo3} alt="" />
                        </li>
                        <li>
                            <img src={partnerLogo12} alt="" />
                        </li>
                        <li>
                            <img src={partnerLogo5} alt="" />
                        </li>
                        <li>
                            <img src={partnerLogo6} alt="" />
                        </li>
                        <li>
                            <img src={partnerLogo7} alt="" />
                        </li>
                        <li>
                            <img src={partnerLogo8} alt="" />
                        </li>
                        <li>
                            <img src={partnerLogo9} alt="" />
                        </li>
                        <li>
                            <img src={partnerLogo14} alt="" />
                        </li>
                        <li>
                            <img src={partnerLogo11} alt="" />
                        </li>
                        <li>
                            <img src={partnerLogo18} alt="" />
                        </li>
                        <li>
                            <img src={partnerLogo2} alt="" />
                        </li>
                        <li>
                            <img src={partnerLogo13} alt="" />
                        </li>
                        <li>
                            <img src={partnerLogo15} alt="" />
                        </li>
                        <li>
                            <img src={partnerLogo1} alt="" />
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default EcoSystem;