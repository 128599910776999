import DefaultLayout from "../layouts/DefaultLayout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "react-fullscreen-loading";
import { useState } from "react";
import { forgotPassword } from '../api/webApiControl';
import validator from 'validator';

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState("");

    const onValueChange = (event) => {
        if (validator.isEmpty(event.target.value) || (!validator.isEmail(event.target.value))) {
            setEmailError("Please enter valid email");
        } else {
            setEmailError("");
        }
        setEmail(event.target.value)
    };


    const sendLinkForForgotPassword = async (e) => {
        e.preventDefault();
        if (!emailError) {
            setLoading(true);
            try {
                const response = await forgotPassword({ email });
                setLoading(false);
                toast.success(response.data.message);
            }
            catch (error) {
                setLoading(false);
                toast.error(error.response.data.message);
            }
        }
    };
    return (
        <DefaultLayout>
            <ToastContainer />
            <div>
                <Loading loading={loading} background="#0046B8" loaderColor="#3498db" />
            </div>
            <div className="logingraytop" id="">
                <div className="logingray">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 mx-auto">
                                <div className="logingrayinn walletgray">
                                    <p>
                                        Forgot password
                                    </p>
                                    <br />
                                    <form id="loginForm" className="row" onSubmit={sendLinkForForgotPassword}>
                                        <div className="form-group forgotmain">

                                            <input type="text" name="email" onChange={(event) => onValueChange(event)} required autoFocus className="form-control" id="inputLogin" placeholder="Email address" autoComplete="email" />

                                            <div className='text-danger'>
                                                {emailError ? (<label>
                                                    Please enter valid email
                                                </label>) : ''}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button className="apply-now" id="loginbtnn">Submit <span id="waitLoginID" className="loader-4"> </span></button>
                                        </div>


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    )
}

export default ForgotPassword;