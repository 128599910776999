import Home from './views/Home';
import Register from './views/Register';
import Login from './views/Login';
import Profile from './views/Profile';
import CreateWallet from './views/CreateWallet';
import SupportedWallets from './views/SupportedWallets';
import ResetPassword from './views/ResetPassword';
import ForgotPassword from './views/ForgotPassword';
const routes = [
    {
        path: '/',
        private: false,
        element: Home
    },
    {
        path: '/register/:token',
        private: false,
        element: Register
    },
    {
        path: 'login',
        private: false,
        element: Login
    },
    {
        path: 'profile',
        private: true,
        element: Profile
    },
    {
        path: 'createwallet',
        private:true,
        element: CreateWallet
    },
    {
        path: 'supportedWallets',
        private:false,
        element: SupportedWallets
    },
    {
        path: 'forgot-password',
        private: false,
        element: ForgotPassword
    },
    {
        path: 'reset-password/:token',
        private: false,
        element: ResetPassword
    }
]

export default routes;