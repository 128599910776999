import arrowIcon from "../../assets/images/icons/left-arrow.svg";
import banefitsImg1 from "../../assets/images/banefits-img-1.png";
import banefitsImg2 from "../../assets/images/banefits-img-2.png";
import banefitsImg3 from "../../assets/images/banefits-img-3.png";
import banefitsImg4 from "../../assets/images/banefits-img-4.png";
import banefitsImg5 from "../../assets/images/banefits-img-5.png";
import { Link } from "react-router-dom";
import * as bootstrap from "bootstrap";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../services/jwt_service";

function Benifits() {
  const navigate = useNavigate();

  function showJoinModal() {
    if (getToken()) {
      navigate('/profile');
    } else {
      let myModal = new bootstrap.Modal(document.getElementById('staticBackdrop'), {});
      myModal.show()
    }
  }
  return (
    <>




      <section className="banefits">
        <div className="container">
          <h1>The future of social networking is based on 
<br/> Trust & Community </h1>
          <div className="row">

            <div className="col-lg-4">
              <button
                className="banefits-box banefits-box-type-1"
                style={{ backgroundImage: `url(${banefitsImg1})` }}
                onClick={showJoinModal}
              >
                <div className="box-text">
                  <h4>Token sale is arriving</h4>

                  <h2>Be the <strong> First </strong> to <strong> Invest </strong> </h2>

                  <span className="left-arrow">
                    <img src={arrowIcon} alt="" />
                  </span>
                </div>
              </button>
            </div>
            
            <div className="col-lg-4">
              <button
                className="banefits-box banefits-box-type-2 mb-4"
                to="/"
                style={{ backgroundImage: `url(${banefitsImg2})` }} onClick={showJoinModal}
              >
                <div className="box-text">
                  <div>
                    <h4>Company office in metaverse</h4>
                    <h3>Build Now</h3>
                  </div>
                  <span className="left-arrow">
                    <img src={arrowIcon} alt="" />
                  </span>
                </div>
              </button>
         

                  <button
                className="banefits-box banefits-box-type-2 banefits-box-type-3 mb-4"
                to="/"
                style={{ backgroundImage: `url(${banefitsImg4})` }} onClick={showJoinModal}
              >
                <div className="box-text">
                  <div>
                    <h4>Host social events </h4>
                    <h3>Invite friends</h3>
                     <span className="left-arrow">
                                        <img src={arrowIcon} alt="" />
                                      </span>

                  </div>
                 
                </div>
              </button>
            </div>
            
            <div className="col-lg-4">
               <button
                className="banefits-box banefits-box-type-2 banefits-box-type-3  banefits-box-type-4 mb-4"
                to="/"
                style={{ backgroundImage: `url(${banefitsImg3})` }} onClick={showJoinModal}
              >
                <div className="box-text blackboxtxt">
                  <div>
                    <h4>NFT marketplace & trading</h4>
                    <span className="left-arrow toparrow">
                      <img src={arrowIcon} alt="" />
                    </span>
                  </div>

                </div>
              </button>


              <button
                className="banefits-box banefits-box-type-2 lastbanefits"
                to="/"
                style={{ backgroundImage: `url(${banefitsImg5})` }} onClick={showJoinModal}
              >
                <div className="box-text lastbanefitsup">
                  <div className="lastbanefitsinn">
                   

                    <h3>Join our community
                      <span className="left-arrow">
                        <img src={arrowIcon} alt="" />
                      </span>

                    </h3>
                  </div>

                </div>
              </button>
            </div>


          </div>
        </div>
      </section>




      <section className="banefits newbenifit">
        <div className="container">
        <h1>Register and get <br/> Exclusive NFTs, assets and XDC tokens for free</h1>
          <div className="row">
            <div className="col-lg-6 col-6">
              <Link
                className="banefits-box banefits-box-type-1"
                to="/"
                style={{ backgroundImage: `url(${banefitsImg1})` }} onClick={showJoinModal}
              >
                <div className="box-text">
                  <h4>Premium <br /> Access</h4>
                  <h2>First 5000 users</h2>
                  <span className="left-arrow">
                    <img src={arrowIcon} alt="" />
                  </span>
                </div>
              </Link>


              <Link
                className="banefits-box banefits-box-type-2 banefits-box-type-3 mb-4"
                to="/"
                style={{ backgroundImage: `url(${banefitsImg4})` }} onClick={showJoinModal}
              >
                <div className="box-text">
                  <div>
                    <h4>Early Resident Benefits</h4>
                  </div>
                  <span className="left-arrow">
                    <img src={arrowIcon} alt="" />
                  </span>
                </div>
              </Link>



            </div>
            <div className="col-lg-6 col-6">
              <Link
                className="banefits-box banefits-box-type-2 mb-4"
                to="/"
                style={{ backgroundImage: `url(${banefitsImg2})` }} onClick={showJoinModal}
              >
                <div className="box-text">
                  <div>
                    <h4>Avatar Looks</h4>
                    <h3>Customize</h3>
                  </div>
                  <span className="left-arrow">
                    <img src={arrowIcon} alt="" />
                  </span>
                </div>
              </Link>

              <Link
                className="banefits-box banefits-box-type-2"
                to="/"
                style={{ backgroundImage: `url(${banefitsImg5})` }} onClick={showJoinModal}
              >
                <div className="box-text blackboxtxt">
                  <div className="blackboxtxtinn">
                    <h4>XDC Tokens</h4>
                    <h3>Claim Now
                      <span className="left-arrow side">
                        <img src={arrowIcon} alt="" />
                      </span>

                    </h3>
                  </div>
                </div>
              </Link>
              <Link
                className="banefits-box banefits-box-type-2 banefits-box-type-3"
                to="/"
                style={{ backgroundImage: `url(${banefitsImg3})` }} onClick={showJoinModal}
              >
                <div className="box-text blackboxtxt">
                  <div>
                    <h4>Free NFT Land</h4>
                    <h3> <span className="left-arrow toparrow">
                      <img src={arrowIcon} alt="" />
                    </span> Claim Now</h3>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Benifits;
