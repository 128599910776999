import DefaultLayout from "../layouts/DefaultLayout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "react-fullscreen-loading";
import { useState } from "react";
import { resetPassword } from '../api/webApiControl';
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

function ResetPassword() {
    let userData = {
        password: "",
        cpassword: "",
        token: "",
    };
    const location = useLocation();
    let a = location.pathname.split("/");
    userData.token = a[2];
    const [user, setUser] = useState(userData);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    
    const onValueChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };
    const changePassword = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await resetPassword(user);
            setLoading(false);
            if (response.data.statusCode === 200) {
                toast.success(response.data.message);
                setTimeout(() => {
                    navigate("/login");                    
                }, 2000);
            } else {
                toast.error(response.data.message);
            }
        }
        catch (error) {
            setLoading(false);
            toast.error(error.response.data.message);
        }
    };
    return (
        <DefaultLayout>
            <ToastContainer />
            <div>
                <Loading loading={loading} background="#0046B8" loaderColor="#3498db" />
            </div>
            <div className="logingraytop" id="">
                <div className="logingray">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 mx-auto">
                                <div className="logingrayinn walletgray">
                                    <p>
                                        Reset Password
                                    </p>
                                    <br></br>
                                    <form id="loginForm" className="row" onSubmit={changePassword}>
                                        <div className="form-group">
                                            <input type="password" name="password" onChange={(event) => onValueChange(event)} required autoFocus className="form-control" id="inputLogin" placeholder="New Password" />
                                        </div>
                                        <div className="form-group">
                                            <input type="password" name="cpassword" onChange={(event) => onValueChange(event)} required autoFocus className="form-control" id="inputLogin" placeholder="Confirm Password" />
                                        </div>
                                        <div className="form-group">
                                            <button className="apply-now" id="loginbtnn">Submit <span id="waitLoginID" className="loader-4"> </span></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    )
}

export default ResetPassword;