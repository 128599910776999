import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import routes from "./routes";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./App.css";
import CookieConsent from "react-cookie-consent";

//Blockchain imports
import Web3 from "web3";
import abi from "./abi.json";
import { getToken } from "./services/jwt_service";






const daiAddress = '0x95CD429Cb98d58eFBbF179380582B114B78a4d40';
const sender = '0xC31B0Ab19704cbcbD9073Da86a53C3cEb8Bb81cB';  //my address as operator
const amountToSend = 5;
let account;

function App() {
  const [currentAccount, setCurrentAccount] = useState(null);

  const checkWalletIsConnected = async () => {
    const { ethereum } = window;
    if (ethereum) {
      window.web3 = new Web3(window.web3.currentProvider);
      await ethereum.enable();
      window.web3.eth.getAccounts(function (err, accounts) {
        if (err != null) {
          alert("Error retrieving accounts.");
          return;
        }
        if (accounts.length == 0) {
          alert(
            "No account found! Make sure the Ethereum client is configured properly."
          );
          return;
        }
        console.log(accounts);
        account = accounts[0];
        console.log(`Account: ${accounts}`);
        setCurrentAccount(accounts[0]);
        window.web3.eth.defaultAccount = account;
        // window.web3.eth.defaultAccount = daiAddress;
      });
      try {
        // ask user for permission
        ethereum.enable();
        // user approved permission
      } catch (error) {
        // user rejected permission
        console.log("user rejected permission");
      }
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
      // no need to ask for permission
    } else {
      console.log(
        "Non-Ethereum browser detected. You should consider trying xDC Wallet!"
      );
    }
  };

  const fundContract = async () => {
    window.contract = await new window.web3.eth.Contract(abi, daiAddress);
    const fund = await window.contract.methods.fundContract().send({
      'from': account,
      'value': window.web3.utils.toHex(window.web3.utils.toWei(amountToSend.toString(), 'ether'))
    });
  }

  const sendxDC = async () => {
    console.log(account);
    if (!account) {
      console.log('Please install xDC Wallet and setup account');
    }
    console.log('Initialize Payment');
    window.contract = await new window.web3.eth.Contract(abi, daiAddress);
    console.log(account);
    const sendX = await window.contract.methods.sendXDC('0x9ed8400f0c5f9508d33c90038427e92b626658cb', 5).send({
      'from': '0xC31B0Ab19704cbcbD9073Da86a53C3cEb8Bb81cB',
      // gasPrice: window.web3.utils.toHex('10000000000'),
      // gasLimit: window.web3.utils.toHex('3000000'),
      'value': '0',
      'contractAddress': daiAddress
    }).on('transactionHash', function (hash) {
      console.log(hash);
    })
      .on('confirmation', function (confirmationNumber, receipt) {
        console.log(confirmationNumber);
      })
      .on('receipt', function (receipt) {
        // receipt example
        console.log(receipt);
      });

  }

  const getBalance = async () => {
    console.log(account);
    if (!account) {
      console.log('Please install xDC Wallet and setup account');
    }
    console.log('Initialize Payment');
    window.contract = await new window.web3.eth.Contract(abi, daiAddress);
    const getBal = await window.contract.methods.getBalance().call();
    console.log(window.web3.utils.fromWei(getBal, 'ether') + ' ether');
    console.log(getBal + ' wei');
  }

  useEffect(() => {
    // checkWalletIsConnected();
  }, []);

  return (
    <BrowserRouter>
      <CookieConsent
        location="bottom"
        buttonText="Allow all cookies"
        enableDeclineButton={true}
        declineButtonText="Reject cookies"
        cookieName="cookie"
        style={{
          borderRadius: "10px",
          background: "rgba(255, 255, 255, 0.82)",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          right: "0%",
          position: "fixed",
          width: "85%",
          zIndex: "999",
          borderRadius: "10px",
          bottom: "0",
          left: "50%",
          transform: "translate(-50%, 0%)",
        }}
        expires={150}
        buttonStyle={{
          background: "linear-gradient(to right, #6ec9ff , #0295f9)",
          color: "white",

          borderRadius: "4px",

          border: "0px none",

          borderRadius: "4px",

          boxShadow: "none",

          color: "white",

          cursor: "pointer",

          flex: "0 0 auto",

          padding: "5px 10px",

          margin: "10px 0 0 -10px",

          display: "inline-block",

          width: "100%",
          fontFamily: "Lufga-Medium",

          fontSize: "16px",
        }}
        declineButtonStyle={{
          background: "linear-gradient(to right, #6ec9ff , #0295f9)",
          color: "white",

          borderRadius: "4px",

          border: "0px none",

          borderRadius: "4px",

          boxShadow: "none",

          color: "white",

          cursor: "pointer",

          flex: "0 0 auto",

          padding: "5px 10px",

          margin: "10px 0 0 -10px",

          display: "inline-block",

          width: "100%",
          fontFamily: "Lufga-Medium",

          fontSize: "16px",
        }}
      >
        <h1
          style={{
            color: "#000",
            display: "block",
            color: "#020202",
            paddingBottom: "10px",
            fontSize: "26px",
            fontWeight: "500",
          }}
        >
          This website uses cookies
        </h1>
        <p
          style={{
            color: "#000",
            display: "block",
            fontSize: "16px",
            fontWeight: "400",
          }}
        >
          {" "}
          We use cookies to personalise content and ads, to provide social media
          features and to analyse our traffic. We also share information about
          your use of our site with our social media, advertising and analytics
          partners who may combine it with other information that you’ve
          provided to them or that they’ve collected from your use of their
          services.{" "}
          <a
            className="cookiepolicy"
            href="https://s3.eu-central-1.amazonaws.com/static.metabloqs.com/public/Cookie_Policy.pdf"
            target="_blank"
          >
            {" "}
            Cookie Settings{" "}
          </a>{" "}
        </p>{" "}
      </CookieConsent>
      <Routes>
        {routes.map((route, index) => {
          if (route.private) {
            return (
              <Route path={route.path} element={<RequireAuth><route.element {...route} /></RequireAuth>} key={index}></Route>
            );
          } else {
            return (
              <Route path={route.path} element={<route.element />} key={index}></Route>
            );
          }
        })}
      </Routes>
    </BrowserRouter>
  );
}

function RequireAuth({ children }) {
  let next = `/login?next=${children.props.path}`;
  return getToken() ? children : <Navigate to={next}  replace />;
}

export default App;
