import { Link } from "react-router-dom";
import logo from "../../assets/images/brand-logo/logo.png";

import social1 from "../../assets/images/icons/linkedin.png";
import social2 from "../../assets/images/icons/discord.png";
import social3 from "../../assets/images/icons/bear.png";
import social4 from "../../assets/images/icons/Medium.png";
import social5 from "../../assets/images/icons/Twitter.png";
import social6 from "../../assets/images/icons/Facebook.png";
import social7 from "../../assets/images/icons/Instagram.png";
import telegramIcon from '../../assets/images/icons/telegram.png'
import { useNavigate } from "react-router-dom";

  



function Footer() {

  const navigate = useNavigate();

  function showJoinModal() {
    if (localStorage.getItem('userJwtToken')) {
      navigate('/profile');
    } else {
      navigate('/login');
    }
  }

    return (




<div className="gapside">



 <footer>
  <div className="container">
    <div className="topheader">
      <div className="row">
        <div className="col-lg-8">
          <div className="foot-logo"> <img src={logo} alt="" /> </div>
        </div>
        <div className="col-lg-4 footer-info">
          <ul className="footer-social">
            <li> <a href="https://www.linkedin.com/authwall?trk=ripf&trkInfo=AQGFoY3sxqAgugAAAX8__CrI9de4uJsOIKY0Titd_A21DSsQ5DAIKaEH2mnYAvJrEIZAixYGdqrt6rTQMFeGsAtLXtk-6gTM1qql5z9GjGUgSR9lr-21cxBiR6bQ5ggic-XmKvo=&originalReferer=https://yeorder.com/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F71723804%2Fadmin%2F" target="_blank"> <img src={social1} alt="" /></a> </li>
            <li> <a href="https://discord.com/invite/TQzsKxygfD" target="_blank"> <img src={social2} alt="" /></a> </li>
            <li> <a href="https://www.reddit.com/r/metabloqs" target="_blank"> <img src={social3} alt="" /></a> </li>
            <li> <a href="https://metabloqs.medium.com/" target="_blank"> <img src={social4} alt="" /></a> </li>
            <li> <a href="https://twitter.com/metabloqs" target="_blank"> <img src={social5} alt="" /></a> </li>
            <li> <a href="https://www.facebook.com/Metabloqs-101969925737968" target="_blank"> <img src={social6} alt="" /></a> </li>
            <li> <a href="https://www.instagram.com/meta.bloqs/?utm_medium=copy_link" target="_blank"> <img src={social7} alt="" /></a> </li>
            <li> <a href="https://t.me/MetabloqsOfficial" target="_blank"> <img src={telegramIcon} alt="" /></a> </li>
          </ul>
          
        </div>
      </div>
      
      
      <div className="row">
        <div className="col-lg-4 footer-info">
          
          <h4>About</h4>
          <ul className="footer-links">
            <li> <a href="" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Apply for Rewards</a> </li>
             <li> <a href="javascript:void(0)" onClick={showJoinModal}>Invite your Friends</a> </li>

            <li> <a href="#eco">Ecosystem</a> </li>
            <li> <a href="#team">Team</a> </li>
          </ul>
        </div>
        <div className="col-lg-4 footer-info">
          <h4>Contact</h4>
          <ul className="footer-links">
            <li> <a href="mailto:info@metabloqs.com">info@metabloqs.com</a> </li>
            <li> <a href="mailto:press@metabloqs.com">press@metabloqs.com</a> </li>
            <li> <a href="mailto:career@metabloqs.com">career@metabloqs.com</a> </li>
          </ul>
        </div>
        <div className="col-lg-4 footer-info lastinfo">
          
          <ul className="footer-links">
            <li> <a href="https://static.metabloqs.com/public/Metabloqs_Whitepaper.pdf" rel="noreferrer" target="_blank">Metabloqs Whitepaper</a> </li>
            <li> <a href=" https://xinfin.org/" rel="noreferrer" target="_blank">Xinfin Blockchain Network</a> </li>
            <li> <a href="https://static.metabloqs.com/public/Airdrop_Terms_and_Conditions.pdf" rel="noreferrer" target="_blank">Terms & Conditions</a> </li>
            <li> <a href="https://static.metabloqs.com/public/Privacy_Policy.pdf" rel="noreferrer" target="_blank">Privacy Policy</a> </li>
            <li> <a href="https://static.metabloqs.com/public/Cookie_Policy.pdf" rel="noreferrer" target="_blank"> Cookie Policy</a> </li>
          </ul>
        </div>
      </div>
    </div>

    <div className="topheader">
    <div className="row">
      <div className="col-lg-12">
        <p className="footer-bottom">&copy; 2022 Metabloqs. All Rights Reserved.</p>
      </div>
    </div>
    </div>
  </div>
</footer>

</div>
    )
}

export default Footer;