import DefaultLayout from "../layouts/DefaultLayout";

import HeroBanner from "../components/containers/HeroBanner";
import Cta from "../components/containers/Cta";

import Benifits from "../components/containers/Benifits";
import Gallery from "../components/containers/Gallery";
import EcoSystem from "../components/containers/EcoSystem";
import OurTeam from "../components/containers/OurTeam";
import Register from "../components/popups/register";

function Home() {
  return (
    <DefaultLayout>
      <HeroBanner />
      <Cta />
      <Benifits />
      <Gallery />
      <Register />
      <EcoSystem />
      <OurTeam />
    </DefaultLayout>
  );
}

export default Home;
