import living from "../../assets/images/meta-living.png";
import * as bootstrap from "bootstrap";
import { useNavigate } from "react-router-dom";



import galleryImg1 from "../../assets/images/gallery-img-1.png";

import galleryImg2 from "../../assets/images/gallery-img-2.png";

import galleryImg3 from "../../assets/images/gallery-img-3.png";

import galleryImg4 from "../../assets/images/gallery-img-4.png";

import arrow from "../../assets/images/sky-arrow.png";
import { getToken } from "../../services/jwt_service";




function Gallery() {
    const navigate = useNavigate();

    function showJoinModal() {
        if (getToken()) {
            navigate('/profile');
        } else {
            let myModal = new bootstrap.Modal(document.getElementById('staticBackdrop'), {});
            myModal.show()
        }
    }
    return (
        <section className="gallery">
            <div className="container">
                <div className="gallery-section-title">
                    <p> Go Beyond Your Reality</p>
                    <p>Immerse in the magic of Technology & build for Connection 3.0</p>
                    <ul className="networkmain">
                        <li className="network">Network.</li>
                        <li className="play">Play.</li>
                        <li className="learn">Learn.</li>
                    </ul>
                </div>
                <div className="slides">
                    <ul className="gallery-list">
                        <div className="slide">
                            <span>
                                <li className="gallery-item clearfix">
                                    <div className="gallery-text">
                                        <h2>Meta Paris</h2>
                                        <p>Experience a new Paris!
                                            Immerse in the streets of
                                            Meta-City Paris and explore the future of human connection.</p>

                                    </div>
                                    <div className="gallery-img">
                                        <img src={galleryImg1} alt="" />
                                    </div>
                                </li>

                            </span>
                        </div>

                        <div className="slide">
                            <span>
                                <li className="gallery-item gallerboxleftbg clearfix">
                                    <div className="gallery-img">
                                        <img src={galleryImg2} alt="" />
                                    </div>
                                    <div className="gallery-text">
                                        <p>

                                            Build Meta-office, host virtual events & meetings and engage in meaningful conversations to

                                            <span> Share Value </span> </p>

                                        <a href="javascript:void(0)" onClick={showJoinModal}>Build Meta-Office  <img src={arrow} alt="" /></a>



                                    </div>






                                </li>


                            </span>
                        </div>
                        <div className="slide">
                            <span>
                                <li className="gallery-item  clearfix">
                                    <div className="gallery-text">

                                        <p> <span> Play Sports </span> with friends or players across the world and earn tokens and other rewards.
                                        </p>


                                        <a href="javascript:void(0)" onClick={showJoinModal}>Invite  your Team  <img src={arrow} alt="" /></a>

                                    </div>
                                    <div className="gallery-img">
                                        <img src={galleryImg3} alt="" />
                                    </div>
                                </li>

                            </span>
                        </div>
                        <div className="slide">
                            <span>
                                <li className="gallery-item gallerboxleftbg clearfix">

                                    <div className="gallery-img">
                                        <img src={galleryImg4} alt="" />
                                    </div>
                                    <div className="gallery-text">
                                        <p><span> Virtual Learning </span>
                                            can be fun. Web 3.0 immersive education classes as a way to learn and earn tokens.
                                        </p>

                                        <a href="javascript:void(0)" onClick={showJoinModal}>Learn and  Grow  <img src={arrow} alt="" /></a>
                                    </div>



                                </li>

                            </span>
                        </div>
                    </ul>
                </div>



                <div className="metaliving">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="metalivingleft">
                                <h3>Meta living is Here!</h3>
                                <span> Join us in this Dream <br></br>
                                    & be a Part of the Web 3.0 reality.</span>
                                <p> Own your Virtual Space. <br></br>Early Metabloq Citizens get Free Assests, NFTs & XDC Tokens.</p>
                                <button className="button button-blue" onClick={showJoinModal}>Get Started</button>
                                <div className="metalivingright showcube">
                                    <img src={living} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 hidecude">
                            <div className="metalivingright">
                                <img src={living} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Gallery;