import built1 from "../../assets/images/built-bg1.png";
import built2 from "../../assets/images/built-bg2.png";
import * as bootstrap from "bootstrap";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../services/jwt_service";

function Cta() {
  const navigate = useNavigate();

  function showJoinModal() {
    if (getToken()) {
      navigate('/profile');
    } else {
      let myModal = new bootstrap.Modal(document.getElementById('staticBackdrop'), {});
      myModal.show()
    }
  }
  return (
    <section className="cta" id="cta">
      <div className="row">

      <h2>Inspired by the real world </h2>

      <p>Metabloqs is the trusted metaverse for people seeking <br></br> authentic connections and experiences</p>


        <div className="newvid">
          <div class="videbox">
            <video playsinline autoPlay loop muted class="headervid" src="https://static.metabloqs.com/public/Metabloqs.mp4"> </video>
          </div>
          <ul className="builtmain">
            <li> <img src={built1} alt="" /> </li>
            <li>Built on</li>
            <li> <img src={built2} alt="" /> </li>
          </ul>
          <button className="button button-blue" onClick={showJoinModal}>Join Now</button>
        </div>
      </div>
    </section>
  )
}

export default Cta;