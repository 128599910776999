import DefaultLayout from "../layouts/DefaultLayout";
import React, { useState, useEffect, useContext } from "react";
import { updateWalletAddress } from "../api/webApiControl";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "react-fullscreen-loading";

import banner1 from "../assets/images/hero-banner-1.png";
import validator from 'validator';


import { Link } from "react-router-dom";


import infobtn from "../assets/images/info.png";


function CreateWallet() {
  const [wallet_address, setWalletAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const updateWalletAddress1 = async (event) => {
    event.preventDefault();
    if (!validator.isEmpty(wallet_address)) {
      setLoading(true);
      const response = await updateWalletAddress({wallet_address });
      setLoading(false);
      if (response.data.statusCode === 200) {
          toast.success(response.data.message);
          navigate('/profile')
      } else {
          toast.error(response.data.message);
      }
    }

}
  return (
    <DefaultLayout>
      <ToastContainer />
      <div>
        <Loading loading={loading} background="#0046B8" loaderColor="#3498db" />
      </div>
      <div className="logingraytop" id="">
        <div className="logingray">
          <div className="container">
            <div className="row">
              <div className="col-md-9 mx-auto">
                <div className="logingrayinn walletgray">
                  <form id="loginForm" className="row" onSubmit={updateWalletAddress1}>
                    <div className="form-group createinfo">
                      <input type="text" name="user_name" onChange={(event) => setWalletAddress(event.target.value)} required autoFocus className="form-control" id="inputLogin" placeholder="Any XDC network supported wallets address such as XDC Web Wallet, XinPay, etc" autoComplete="new-password" />
                    
                       <Link to="/supportedWallets" target="_blank" > <img src={infobtn} alt="" /> </Link>

                    </div>
                    <div className="form-group">
                      <button className="apply-now" id="loginbtnn">Submit <span id="waitLoginID" className="loader-4"> </span></button>
                    </div>
                    <div className="twobtnwallet form-group">
                      <h3> Don't have wallet address? </h3>
                      <p>Create wallet at</p>
                      <span className="metamstbtnup"> <a href="https://wallet.xinfin.network/#/" target="_blank" className="xinfinbtn">  XDCPay </a> </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );

}

export default CreateWallet;
